<div class="uk-cover-container uk-height-normal">
  <div class="ratio ratio-21x9 animate__animated animate__zoomIn">
    <iframe
      src="https://www.youtube.com/embed/7E-uPPqSrVg?autoplay=1&amp;wmode=transparent"
      allowfullscreen
      uk-cover
    ></iframe>
  </div>
</div>
<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-3 p-3 text-center animate__animated animate__rubberBand"
    >
      <a href="/"
        ><img
          class="img-fluid rounded"
          width="100%"
          src="https://1.bp.blogspot.com/-4mw1TwHfTgA/YP-VkY42B8I/AAAAAAAAQBE/7OBS3vsCjc4VTi_nBAz2eTRGOb9lZASFACLcBGAsYHQ/s960/55503419_10214161804980050_7173422848424280064_o.jpg"
      /></a>
    </div>
    <div class="col-12 col-md-9 p-3 text-center">
      <div
        class="p-3 p-md-5 shadow rounded bg-dark animate__animated animate__zoomIn"
      >
        <h5 class="text-white">Welcome to my site..</h5>
        <p class="text-white">
          Saya seorang pejantan tangguh berasal dari indonesia, menyukai seni
          photography digital art dan tentunya belajar codding , salam kenal
          dari saya.<br />Terima kasih untuk dukungan dari
          <a
            href="https://vercel.com"
            target="_blank"
            class="text-white"
            >vercel</a
          >
          sebagai host , dan <a href="https://github.com">github</a> untuk deploy repo saya plus 
          <a href="https://stackblitz.com" target="_blank" class="text-white"
            >stackblitz</a
          >
          tempat saya ngoding , serta
          <a href="https://angular.io/" target="_blank" class="text-white"
            >angular</a
          >,<a
            href="https://getbootstrap.com/"
            class="text-white"
            target="_blank"
            >bootstrap</a
          >
          feat
          <a href="https://getuikit.com/" target="_blank" class="text-white"
            >Uikit</a
          >
          hingga
          <a href="https://animate.style/" target="_blank" class="text-white"
            >animate</a
          >
          sebagai framework website ini.
        </p>
        <button
          class="btn bg-dark text-white shadow"
          type="button"
          uk-toggle="target: #offcanvas-usage"
        >
          The Art Gallery
        </button>
      </div>
    </div>
    <div class="col-12 col-md-12 p-3"></div>
  </div>
</div>
