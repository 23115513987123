<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Conceptual Art Photography</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-A_NlLCSEs90/YLfR9k56TyI/AAAAAAAAOxk/X6j-0EqQJikkKUjuyCWXOSHHxWchijKmACLcBGAsYHQ/s2048/23415535_10210768584791666_6275021180249258681_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-RGC3u1jiELA/YLfSICF1VSI/AAAAAAAAOzU/n6B1ebgIq54FrV_nn8tNva3589QlHHQugCLcBGAsYHQ/s2048/49608166_10213717839081180_4982751856859021312_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-w5glawzwFg8/YLfSH_n6M3I/AAAAAAAAOzQ/e4Tge4tfbE4GMUzslLppMuR0PoPDleeWACLcBGAsYHQ/s2048/48373161_10213508735453720_6189003407739584512_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-YBG-6_aO4Ao/YLfSF_tc7HI/AAAAAAAAOy4/x-f5NN1QVrQhjYvKqiOa03z8NsITFHmlwCLcBGAsYHQ/s2048/45387107_10213282163949574_6989754679454334976_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-m35-InVTVA4/YLfSC8zR6RI/AAAAAAAAOyc/DQcBknqi28APaOOuhzBzsVLGPuxCmXyPACLcBGAsYHQ/s2048/36870944_10212502263492550_7984323407032352768_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-yT34gHKU_p8/YLfSB-QUlGI/AAAAAAAAOyQ/cGpkkRx_GqA6fK2JwbiRpTYz93K_6VkTgCLcBGAsYHQ/s2048/28337927_10211544031577351_968734448723016761_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-GV2T05zt5z8/YLfR-v06NmI/AAAAAAAAOxw/yR-PAq4-lco5mJeL-aCSHeud_dwcv6DEQCLcBGAsYHQ/s2048/26170414_10211085178906321_9203449801860442032_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-Ja-SAE7ojmg/YLfR8ghALqI/AAAAAAAAOxc/G1UKsc4pc-USXbI6-wDRArvqTqDIAywLQCLcBGAsYHQ/s2048/20626297_10210074351316263_236087673830960779_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-yZcP4TgxQds/YLfR4ai9DgI/AAAAAAAAOww/BjwwCUItpgQC8J_sqwOFbR4ImwEMlckSgCLcBGAsYHQ/s960/12832490_10205921983029651_6599452108033408023_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-Tld34TGFrXU/YLfR4MwDGQI/AAAAAAAAOws/WknQo-SB9l8qIp7id74BBHDuybDH3owHQCLcBGAsYHQ/s960/12803080_10205997738283485_6444993107678205190_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-CcvpbeDvF8k/YLfR3nG_JMI/AAAAAAAAOwo/HLTYp71SqA0eMBvJcBqJLcy9pD-512khACLcBGAsYHQ/s960/12801563_10206047156958921_9173005057737261087_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-nU8M-Hkfd50/YLfRzrlK3ZI/AAAAAAAAOv4/MOdKH48f_kAETW1x7hwLYrN60S2hIe5-wCLcBGAsYHQ/s960/10441188_10205448751599161_7785426936221353079_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-_IggukhrhFo/YLfRz7BN-wI/AAAAAAAAOv8/pwT7UTGsoocD3lCsuRrdzpwvwnKAOzJrwCLcBGAsYHQ/s960/11074324_10204252289688361_17517557150007977_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-g2Ds5ob9bic/YLfR1J57-XI/AAAAAAAAOwM/9YT3CKQUrgQx64sBQN6w3Juzj9q2y-EIACLcBGAsYHQ/s2048/12068874_10205097406615756_4942640571347379556_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-TNpzvupk4mk/YLfR19ZLUvI/AAAAAAAAOwQ/KcMScBoFmRY8kOhLnbiH7K7P9aSosP5xACLcBGAsYHQ/s960/12294662_10205276190125232_2356033236975392151_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-6n20E9N0coo/YLfR06l7jDI/AAAAAAAAOwI/jRqGcEmN2e8f3z_f-o_oeDpUq-qt-2higCLcBGAsYHQ/s960/11905780_10205316651536742_656470806842160974_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-jfk8bUIhhR0/YLfR3LM3v2I/AAAAAAAAOwg/UOS9jQjVxjQ8CHJSHjPzqQbQr9ID2bxDACLcBGAsYHQ/s960/12662581_10205721697662642_137764652902355848_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-f0qGN3zypcY/YLfR3RNARNI/AAAAAAAAOwk/3p48g3enlwQYlUPiQcmMR_-0W_Cy9pHlACLcBGAsYHQ/s960/12717988_10206072463791576_545335062977284262_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-xlxWzzuWFwY/YLfR4wJ5dKI/AAAAAAAAOw0/GH6g2WroaxAzm0VZmrcpjsdjkyoZMVNbACLcBGAsYHQ/s2048/12957609_10206215897337325_3634420300848193005_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-pVWbGT-2tfc/YLfR5WIjUwI/AAAAAAAAOw4/anMXm38fePAoCFsPmHmKRiV5AsYcnQ5pwCLcBGAsYHQ/s2048/12967403_10206213795844789_1679579863701824931_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/--MYAfsGRkTY/YLfR2SNYOiI/AAAAAAAAOwY/HlsSsXernn8CB4N4Ys0TTfmxLRjBjMEMgCLcBGAsYHQ/s960/12346308_10205344390110189_7998957132143547359_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-3FM3secOOc4/YLfR-hsvEQI/AAAAAAAAOxs/tlk8-2X-O0wT8aIUyEdq_mzya_cVCc10wCLcBGAsYHQ/s2048/25734257_10211062510699630_6028128390988426982_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-a06oO8J5kL0/YLfSFJ0jDxI/AAAAAAAAOyw/o_ihcROWxFo5Us9UyLAUNmICdleViFWuACLcBGAsYHQ/s2048/45163675_10213244503848095_6162866053547294720_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-GgYMlDvrj1I/YLfSE233gSI/AAAAAAAAOys/mb0f_iJ34eMIBuI3LZeGVg-jo4yt-K7UgCLcBGAsYHQ/s2048/45132551_10213255494042843_4396025664566722560_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-nRdOVxq1ttw/YLfSCXE7BdI/AAAAAAAAOyU/cCSz34fVNMoTBc781bO53qKfEZDgNybFgCLcBGAsYHQ/s2048/28828095_10211779176295822_7436896794927931827_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-UOTGF-SSDQ4/YLfSBJxjofI/AAAAAAAAOyI/r7yj2wGPHa4qn2FBZjc2jkFfyc7TgngNgCLcBGAsYHQ/s2048/27993242_10211479641647643_1600469338167722135_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-C4xf1mF7Veo/YLfSCj9EWMI/AAAAAAAAOyY/mmk6aN-H-aAJ6PGBOJPV48r2Fvft5eo9wCLcBGAsYHQ/s2048/29177419_10211685460552987_5379060051312902144_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-oCqMUnlvsj8/YLfSAJpRQDI/AAAAAAAAOyA/_n2irWmR3uM8_xiQMvO-t94nKaAxXBR4gCLcBGAsYHQ/s2048/26686005_10211231046992932_8855559503658796778_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-ryRMbIS6xuU/YLfSDaOfMEI/AAAAAAAAOyg/tFw3_V2-8A4HUOM9m5Cw6YQ3aIgbFAf5wCLcBGAsYHQ/s2048/40684289_10212860808455950_4464485991566016512_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-tZdYHVfn8wc/YLfSJyjpyZI/AAAAAAAAOzo/aY7aovb42FcGQVeKvS5RR0BN6gedK-UHgCLcBGAsYHQ/s2048/53649956_10214111884772076_1286912840585707520_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-NFjykI0svVU/YLfSKSo_PPI/AAAAAAAAOzw/2LG7V7fYfWUsrNhyTbqleVymPyhdZCXUQCLcBGAsYHQ/s2048/55575493_10214174315492805_7908779107003924480_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-__5S8KHhL-Q/YLfSJd7UhoI/AAAAAAAAOzk/ZDxiT4KIZAoh1LONofx8YMNJVBR46X9TgCLcBGAsYHQ/s2048/51682755_10213922670281832_6842664586575872000_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-uGQxE8fF_6w/YLfRz0p8HfI/AAAAAAAAOwA/HV2fUVWPPB0OEn43u0HJWLFOQFMB9Pp6gCLcBGAsYHQ/s2048/10296052_10205454109133096_7982571820643803820_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-zXJZI5So8os/YLfR5dAQRII/AAAAAAAAOw8/2wnoK3lKFMIVV3wog9Ltqxm_Zvrc9DfkgCLcBGAsYHQ/s960/13419272_10206645140588138_2543166287042910822_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-4rYZojqvFdw/YLfR5kHkjcI/AAAAAAAAOxA/QGVHnZM9-qc-FsplopS_5WsHmKzHks3TACLcBGAsYHQ/s960/13614981_10206796059841025_7710079975790305459_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-XSZbHxN1Poc/YLfR6QRh-DI/AAAAAAAAOxE/hgCfdFXQplkBgYUMYOsQK3Xc6mSaH9d6ACLcBGAsYHQ/s2048/14608924_10207481915946999_6040878363128105882_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-gIg6k1B79Zo/YLfR6jtTGqI/AAAAAAAAOxI/pPLKpOv8RrIugerQ9fejfmjixHkDJEzwQCLcBGAsYHQ/s2048/15138409_10207879409764096_2174835022262532037_o.jpg"
      />
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-ZOULgP1kzWI/YLfR7iDFk4I/AAAAAAAAOxQ/XDxIoWhi2YYdIOjKorg0l7OF_bQJ131JwCLcBGAsYHQ/s2048/16143710_10208415623449103_1432783092523920276_o.jpg"
      />
    </div>

    <div class="col-12 col-md-12 p-3 text-center">
      <p>
        <a
          routerLink="/photography/conceptual/darkbeauty"
          class="btn btn-outline-light text-white"
          >Continue to page 2 →</a
        >
      </p>
    </div>
  </div>
</div>
