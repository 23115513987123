<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Conceptual Art Photography</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-3Q9AoeNexIo/YLfR_3wfZuI/AAAAAAAAOx8/VS1NqgWTQdw55HOSortY658BfgngyXy6gCLcBGAsYHQ/s2048/26197695_10211108742975408_416669214281900243_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-ygQz8L3vYoE/YLfR8VyIdVI/AAAAAAAAOxU/87X6pPvC6ZM2QAG7ptUoWsARdN9SSdYZACLcBGAsYHQ/s2048/17546734_10208919616248608_2755341529572397751_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-_B0e_xynDw4/YLfR8cnHHnI/AAAAAAAAOxY/pqaH9AQADtslW-SLYSU6gRA0TStLiPyzQCLcBGAsYHQ/s2048/16252256_10208439374682869_8317921981380984040_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-MmAnhQLkhD0/YLfR92kLnqI/AAAAAAAAOxo/IuRJUYPfv4QpTwsugCjZQmZOVn29JrUiQCLcBGAsYHQ/s2048/24785024_10210908450488221_3101816172786645685_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-duOmSWxas7k/YLfSA-LxebI/AAAAAAAAOyE/ivsog2Ty6JwMtymAShLz5TDzP8GvSrxugCLcBGAsYHQ/s2048/27710326_10211424003456723_3348067605965886478_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-eyrUHz3g40I/YLfSLTytp4I/AAAAAAAAOz8/mc4wG3Q7irAS2UHfK4s5B7opnE35wOIVQCLcBGAsYHQ/s2048/73063573_10215677043060055_7911531326342168576_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-OUjoaJ_4a8A/YLfSLKgp4dI/AAAAAAAAOz4/zE0x2KFGD0g_NVQCuNxJxQznrpIRF_p2ACLcBGAsYHQ/s1542/72181877_10215889486010996_6181722742388162560_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-CizSEXdAHIQ/YLfSHkwcdRI/AAAAAAAAOzM/oyiMca6FUcAt5BdVtFBTB9RvhVL1vgq8gCLcBGAsYHQ/s2508/48210576_10213532940418829_1596098460958851072_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-MGA0Dug13IU/YLfSDuiQHLI/AAAAAAAAOyk/TizLGy2NHwcxg6sT63H5luf0rKxGATUrQCLcBGAsYHQ/s2048/43399106_10213107010290842_2780455376665968640_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-p3qwtH2qUnw/YLfSFfSnbKI/AAAAAAAAOy0/0iyA97u4gQ8g1JwOCvKIdLa8AST3dSVGACLcBGAsYHQ/s2048/45318118_10213272400425492_46833057834991616_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-KiRtCFlySko/YLfSHHPyn-I/AAAAAAAAOzI/LSKub9Y8Mpsj0NJhw8TXbUcy5k9nNWYaACLcBGAsYHQ/s2048/46317716_10213353223086008_6779026399748423680_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-bw0-jyik0YA/YLfSI_VwyfI/AAAAAAAAOzc/TYXMntsOEq8ZPocL4rZAI89PsoSb_CxlACLcBGAsYHQ/s2048/50787676_10213821911722931_6889533030646415360_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-fCOFy96jctc/YLfR2X-pyZI/AAAAAAAAOwU/zocQShBEMB0g3k6tevPuithKV8H_0NCRgCLcBGAsYHQ/s960/12342330_10205387848636625_3782379584056343107_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-OaAfE8SFBrs/YLfR6lgHCmI/AAAAAAAAOxM/M45hE8rVKQc32cr8j_ziSCzSyOJ-0WitwCLcBGAsYHQ/s2048/15250691_10207904368308044_5313407559062352732_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-d1FeB-aViHw/YLfSIupOCPI/AAAAAAAAOzY/N3zuWEsdaCcq8a3a6LMjWVSJqYel6xDaQCLcBGAsYHQ/s2048/49705038_10213666019905733_4483986139522269184_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-1FuR5ayfigE/YLfSKDfVuxI/AAAAAAAAOzs/nK5rqldS320Ovavk6ZSSGUTASWZzfhcLQCLcBGAsYHQ/s2048/54522485_10214137558253897_7061097878697017344_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-QGa91Al8h8Y/YLfSEbbp63I/AAAAAAAAOyo/pv9bZJjppzkeeK2L_Nzolnm2bE7cwUkRQCLcBGAsYHQ/s2048/43851562_10213128074777441_2542877083139309568_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-p_4YmmtRkLw/YLfR2qEj-yI/AAAAAAAAOwc/Zev2Ol_VjXErtS4QYgBGfyY0n0CHp-G1ACLcBGAsYHQ/s960/12573889_10205615253921615_1831087572240032083_n.jpg"
      />
    </div>

    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-MCuSSon83Qs/YLfR_iZh7OI/AAAAAAAAOx4/yHCT0uVNQnkqBbv8aPsgMsUi7uRSyntvwCLcBGAsYHQ/s2048/26173913_10211091155735738_6985323107730628732_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-0VOluXbrrXk/YLfSGJje5JI/AAAAAAAAOy8/knq9HByD-n0kWWis3wKrLrky2dD3CyLSwCLcBGAsYHQ/s2048/45740589_10213312764594571_7198545860190273536_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-roSAOxViNl8/YLfSGvAHIsI/AAAAAAAAOzA/M159-U8cu3oEuTfRrdY7R3cdLDenT8gDACLcBGAsYHQ/s2048/45818912_10213312739513944_3965805963121786880_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-Q2JnW9lGajY/YLfSGw6uuoI/AAAAAAAAOzE/C1TDAEErw1UvlBO5u1LnJCBlIpt7LnZRwCLcBGAsYHQ/s2048/45837048_10213304202420522_4849798219338612736_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-109NYKJnWlo/YLfSJLWn2qI/AAAAAAAAOzg/L7neeZTaiWAgo2kt8jMMj2TrwUzzRNV5gCLcBGAsYHQ/s2048/51078274_10213863020470624_5086140902313820160_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-WzqU0S3J3HI/YLfSK7OiiEI/AAAAAAAAOz0/MG5agWdHE3UeHlDJUX2bpmrcUKhMgf9wgCLcBGAsYHQ/s1875/71828746_10215543827769756_8559723998198366208_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-75PIj828w2E/YLfR_PaE2sI/AAAAAAAAOx0/bLQBdqQUO8YB3BXQRAVDpNVOFBQ0QaTQQCLcBGAsYHQ/s2048/26170731_10211140736655230_1170313066489563346_o.jpg"
      />
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-fq7x-C5lwiM/YLfR9c6ZofI/AAAAAAAAOxg/mGv10ygLxn8mIgCDL34Z1OYV4nhQ8QoEACLcBGAsYHQ/s2048/21083439_10210229676239289_3434954953227862290_o.jpg"
      />
    </div>

    <div class="col-12 col-md-12 p-3 text-center">
      <p>
        <a
          routerLink="/photography/conceptual"
          class="btn btn-outline-light text-white"
          >← page 1</a
        >
        <a
          routerLink="/photography/conceptual/fairy"
          class="btn btn-outline-light text-white"
          >page 3 →</a
        >
      </p>
    </div>
  </div>
</div>
