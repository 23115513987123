<div
  class="uk-cover-container uk-height-large animate__animated animate__zoomIn"
>
  <img
    alt="donasi untuk source code dan creative of art"
    src="https://1.bp.blogspot.com/-oHdm7Tztc6Y/YLcor9VCU7I/AAAAAAAAOYg/A8ZZT5CgNYAJLD-pLStFdE0dAaZXii5fgCLcBGAsYHQ/s960/51155598_10213862983909710_6762192674863710208_n.jpg"
    uk-cover
  />
</div>
<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Buy Me a Coffee</a></h3>
    </div>
    <div class="col-md-2 p-3"></div>
    <div class="col-md-8 p-3 text-center">
      <div class="shadow rounded bg-dark animate__animated animate__zoomIn p-3">
        <h5>
          <a href="/" class="text-white"
            >Psstt... you can make contribute with a donation..</a
          >
        </h5>
        <p class="text-light">
          If our web apps is helping for support your bussiness , so you can
          contribute to me, for make great apps and web template again for
          free... so you can make donation for buy me a coffee... use money
          transfer like western union or moneygram and sent order to we bank
          account.
        </p>
        <div class="border border-light rounded p-3 p-md-5 text-white">
          BANK CENTRAL ASIA
          <br />ACCOUNT NO : 0181884109 <br />ACCOUNT NAME : SUCI CHANIFAH
          <br />IBAN/SWIFT CODE : CENAIDJA
        </div>
      </div>
    </div>

    <div class="col-12 col-md-12 p-3"></div>
  </div>
</div>
