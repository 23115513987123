<div
  class="uk-cover-container uk-height-normal animate__animated animate__zoomIn"
>
  <canvas width="100" height="30"></canvas>
  <div class="ratio ratio-16x9">
    <iframe
      src="https://www.youtube.com/embed/N-zZ89Yr__A?autoplay=1&amp;wmode=transparent"
      frameborder="0"
      allowfullscreen
      uk-cover
    ></iframe>
  </div>
</div>
<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Open Source Code Project</a></h3>
    </div>
    <div class="col-12 col-md-4 p-3 text-center">
      <a
        routerLink="/sourcecode/template"
        style="text-decoration: none"
        class="btn shadow rounded bg-dark animate__animated animate__zoomIn p-3"
      >
        <img
          class="img-fluid"
          alt="free open source code download gratis"
          src="https://1.bp.blogspot.com/-CHypK8tKuVI/YP-WcRjnE1I/AAAAAAAAQBM/izCE8UrOljADx76xC-D1txgmsLx9kD0AgCLcBGAsYHQ/s626/aplikasisekolah%2Bonline%2Bweb%2Bapp%2B%252817%2529.jpg"
        />
        <h5><a href="/" class="text-white">Website Template</a></h5>
        <p class="text-light">
          Website template project free download gratis for develope modern
          website.
        </p>
      </a>
    </div>
    <div class="col-12 col-md-4 p-3 text-center">
      <a
        routerLink="/sourcecode/application"
        style="text-decoration: none"
        class="btn shadow rounded bg-dark animate__animated animate__zoomIn p-3"
      >
        <img
          class="img-fluid"
          alt="free open source code download gratis"
          src="https://1.bp.blogspot.com/-qR4-Zi1a38k/YP-Wcof-JTI/AAAAAAAAQBU/tgf1ZeCqMf8ggBaWcWCQVpTdGS0Ihr13gCLcBGAsYHQ/s626/aplikasisekolah%2Bonline%2Bweb%2Bapp%2B%252814%2529.jpg"
        />
        <h5><a href="/" class="text-white">Bussiness Apps</a></h5>
        <p class="text-light">
          Bussiness application point of sale cashier, inventori management and
          others apps.
        </p>
      </a>
    </div>
    <div class="col-12 col-md-4 p-3 text-center">
      <a
        routerLink="/sourcecode/cms"
        style="text-decoration: none"
        class="btn shadow rounded bg-dark animate__animated animate__zoomIn p-3"
      >
        <img
          class="img-fluid"
          alt="free open source code download gratis"
          src="https://1.bp.blogspot.com/-NsWRUWNGixI/YP-WccYa4DI/AAAAAAAAQBQ/lqgiRVX8-0UgFq9G1I28gr4PnYTYcdchQCLcBGAsYHQ/s626/aplikasisekolah%2Bonline%2Bweb%2Bapp%2B%252815%2529.jpg"
        />
        <h5><a href="/" class="text-white">New CMS</a></h5>

        <p class="text-light">
          New modern Content Management System fast for build and develope
          modern website.
        </p>
      </a>
    </div>
    <div class="col-12 col-md-12 p-3"></div>
  </div>
</div>
