<div
  class="uk-cover-container uk-height-large animate__animated animate__zoomIn"
>
  <img
    alt="donasi untuk source code dan creative of art"
    src="https://1.bp.blogspot.com/-yUfHAzf3CNY/YLduhgcV2AI/AAAAAAAAKII/kRz4E_tTlrMQCLg3QowOJ0q-vMYXUXONgCLcBGAsYHQ/s16000/11896437_10204825580860282_6076729124706789877_o.jpg"
    uk-cover
  />
</div>
<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Hire Me...</a></h3>
    </div>
    <div class="col-md-2 p-3"></div>
    <div class="col-md-8 p-3 text-center">
      <div class="shadow rounded bg-dark animate__animated animate__zoomIn p-3">
        <h5>
          <a href="/" class="text-white"
            >Art freelancer - i need a project and job so hire me..</a
          >
        </h5>
        <p class="text-light">
          Hey... if you have some project or need my art services, so let me
          in.... let's make some noisee...
        </p>
        <img
          class="img-fluid"
          alt="ferlancer art photography programing web developer"
          width="100%"
          src="
        https://1.bp.blogspot.com/-VJjp53snEo4/YLdp1SAlQOI/AAAAAAAAKHo/DVrAVpEwROMqAhMRf-Nyvs99EYtVTs60wCLcBGAsYHQ/s1600/ferlancer%2Bart%2Band%2Btechnology%2B%25281%2529.jpg
        "
        />
        <img
          class="img-fluid"
          alt="ferlancer art photography programing web developer"
          width="100%"
          src="
       https://1.bp.blogspot.com/-XQSn3uJ1xGA/YLdp1CTGWyI/AAAAAAAAKHg/6NV5DAJo-GARfhm_R8cLaaR-Abp_iwJ7gCLcBGAsYHQ/s1600/ferlancer%2Bart%2Band%2Btechnology%2B%25282%2529.jpg
        "
        />
        <img
          class="img-fluid"
          alt="ferlancer art photography programing web developer"
          width="100%"
          src="
      https://1.bp.blogspot.com/-Yrn3LubXHAc/YLdp1Fc1a6I/AAAAAAAAKHk/4ub-6D-OR4YF5l8JtAMhsPpMcnWCUYu9wCLcBGAsYHQ/s1600/ferlancer%2Bart%2Band%2Btechnology%2B%25283%2529.jpg
        "
        />
        <img
          class="img-fluid"
          alt="ferlancer art photography programing web developer"
          width="100%"
          src="
        https://1.bp.blogspot.com/-W_NUtbZAYko/YLdp1wf2bcI/AAAAAAAAKHs/s3EjKFrQ3Xc8jlZHxUctKxRY5uWpgME0wCLcBGAsYHQ/s1600/ferlancer%2Bart%2Band%2Btechnology%2B%25284%2529.jpg
        "
        />
        <img
          class="img-fluid"
          alt="ferlancer art photography programing web developer"
          width="100%"
          src="
       https://1.bp.blogspot.com/-QEElGf_Wut8/YLdp2LqxjEI/AAAAAAAAKHw/dfvz_i4X5SQf7GoO55GGtcooXJbSPOkTQCLcBGAsYHQ/s1600/ferlancer%2Bart%2Band%2Btechnology%2B%25285%2529.jpg
        "
        />
      </div>
    </div>

    <div class="col-12 col-md-12 p-3"></div>
  </div>
</div>
