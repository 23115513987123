<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Photography of Art</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-E9FCsO1KHbY/YLek1gcFRRI/AAAAAAAAOlk/2EKjbqfAgkwJb03uPQWib7rU9J0222HCwCLcBGAsYHQ/s2048/26239994_10211196769776023_3326116275955771255_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-QQBDd-guQDI/YLd5J80_yPI/AAAAAAAAObg/wBVJ6R3SoxIpwhQaD3dZvl5WEEXU60k9ACLcBGAsYHQ/s2048/12890962_10206146031830731_8653409357574200403_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-HDlZpf8sGE8/YLd5J8MFzdI/AAAAAAAAObk/LB86TAVCbZAzmyTfjOpPmTIcRTWioVwpQCLcBGAsYHQ/s2048/12768318_10205850771729413_4746132550456798237_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-avI4eqRDTDY/YLd5KGdWkpI/AAAAAAAAObo/gw89ELwsRDo9jjRMjBod66DZLfRmh3mkgCLcBGAsYHQ/s2048/13244221_10206453125787888_8794054606607258730_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-KdOmgew5AAY/YLd5K6bxNvI/AAAAAAAAObs/470DMPEoFTEOiUz8sypDkUiHaDT0qqdcQCLcBGAsYHQ/s2048/14188290_10207230576463669_4891828439591670232_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-jy2oTQi5j8c/YLd5LbDS-MI/AAAAAAAAObw/Y-YMgkdxzBQgAj-kBcaR8EEecBfRdd7eQCLcBGAsYHQ/s2048/14195401_10207209657740714_8613409697937034184_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-PJQrSSWnTq4/YLd5Lgkdt1I/AAAAAAAAOb0/8FdxlHnobosbJSM1cH7jQ84PFjadGJw_gCLcBGAsYHQ/s2048/14409593_10207344082301244_5223650982309403384_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-A3Yjfwv2zAk/YLd5L7m7tZI/AAAAAAAAOb4/2E-FxOAENtkFzW6fwWkpxMP3BBGhG1MqQCLcBGAsYHQ/s2048/14468567_10207405013184478_8540051352358808553_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-dwpDbfNdJ70/YLd5MQU160I/AAAAAAAAOb8/zDCNRp6w7bgsujptygxmV_YCo_J528EjwCLcBGAsYHQ/s2048/14480467_10207391171958456_7419748018999372263_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-jeIoTUKTCAs/YLd5MtoKQEI/AAAAAAAAOcA/JWbwOKr-zLIYuOpbgVXyPHH05o7b6d8JQCLcBGAsYHQ/s2048/14524998_10207405919047124_6462158047098522542_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-CouWGZ6LqZY/YLd5NDo2J0I/AAAAAAAAOcE/XkRUIl6UjrArobrq57Wd9_jGdfzV_KdeQCLcBGAsYHQ/s2048/15025293_10207781747962612_1865020663178979881_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-GY09uiDFZDs/YLd5NZkR8uI/AAAAAAAAOcI/pINIQoqe_UgBgZUVBjaCL1Z7UUVgp4rugCLcBGAsYHQ/s2048/19221672_10209604773577113_3826082887061753279_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-Cr6mu6xJRzk/YLd5NRrOujI/AAAAAAAAOcM/IjKqlvSzneggdKjJqDI0S6IxT95FXekuQCLcBGAsYHQ/s2048/21122541_10210243975996774_6603656157463110939_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-21j2SNvdlLg/YLd5OQ-p3fI/AAAAAAAAOcQ/wDfRqqrhlOc9ykchq4pjcO4P35KwXOp0gCLcBGAsYHQ/s2048/26685797_10211230660023258_8492602650567590556_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-QZrkVkQRNkg/YLd5OR6zeNI/AAAAAAAAOcU/LLPcw6EoOwgrtiDe1eABpb6LU0qo0L91QCLcBGAsYHQ/s2048/27747826_10211422624982262_1056869908644083205_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-F9MvPzM2cQo/YLd5OoEm-SI/AAAAAAAAOcY/rOeXcuuTD2M9YBtlpYcaFujyFmVfXPqgACLcBGAsYHQ/s2048/29060102_10211779797551353_3791560838111110084_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-fe8VGHIj074/YLd5PAFWJgI/AAAAAAAAOcc/fIDMuTVhFysmRr2GjN0ejjrkTrXdspCEACLcBGAsYHQ/s2048/29351556_10211779349420150_1882795148316816954_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-4phryoUbNcE/YLd5PbOtQ_I/AAAAAAAAOcg/TCkYTXRxo6I9a5J2LzE5kUhreH7XS3IjgCLcBGAsYHQ/s2048/43490690_10213210978689987_659090554369343488_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-d06jQ5JyjNo/YLd5PjmjUQI/AAAAAAAAOck/J63y3BXlWMwNPNPEo9xuH6s_NzloI0ejgCLcBGAsYHQ/s2048/46266456_10213340392965263_8094537258599710720_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-MbBlkAdss3g/YLd5QLI6QtI/AAAAAAAAOco/wcJFGL1Tmy4Wq3M7cvnoziM6ilgdQIMOgCLcBGAsYHQ/s2048/46473210_10213365442671490_8619200928651673600_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-WKEZSuWeCo8/YLd5Qe0fAqI/AAAAAAAAOcs/tgKpeLSAd7cXBQ4qjNBFHCbEFD-voGSRQCLcBGAsYHQ/s2048/46931298_10213426597440321_90713480272805888_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-9atZtg9heyY/YLd5QrDOMzI/AAAAAAAAOcw/k6bAPFYHIgYsJx8WFVEQvv32zVQO0wFrQCLcBGAsYHQ/s2048/47076740_10213424139578876_3997605767837384704_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-S6JKbT58yCk/YLd5Qxi6KaI/AAAAAAAAOc0/W9ziDDnM4AkPV1NX7WeCwty_Z9R2TeFZgCLcBGAsYHQ/s2048/48391451_10213555417860751_913401860092592128_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-ga1k5ww7GJM/YLd5RQv6eEI/AAAAAAAAOc4/uJOSCewq7ykWcZxBFJTZqhatCAC3xNnhQCLcBGAsYHQ/s2508/49206425_10213665503612826_1399088216602050560_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-1Z-K8e9HSto/YLd5RshwoKI/AAAAAAAAOc8/grykSWeYrgkJWlneWLD3st3xIPN8kNc0QCLcBGAsYHQ/s2048/49900289_10213712542108759_5060026375872708608_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-ihFK61kmj78/YLd5R4D5CHI/AAAAAAAAOdA/VxIh73_ELjg2ZUVeaLo3H6zOd5qlyPUMQCLcBGAsYHQ/s2048/49946961_10213688286742390_8857182373578539008_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-ilO2_SnOan0/YLd5Sc4dmpI/AAAAAAAAOdE/-eC15nVDsGIbN67cZ8zGm2MGA2ixlySWQCLcBGAsYHQ/s2048/50456952_10213758512297985_3893665063898185728_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-C7hOFtDVgHw/YLdyFJp8SAI/AAAAAAAAOak/Q4vupEA-iqoJsaRACwm93KrXyeV2z49rgCLcBGAsYHQ/s960/3223.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-bQsAsaZIoI0/YLdyEcJDhQI/AAAAAAAAOac/k3P-yu0YDmEKy4hlEWtyZaPInvyFByE3ACLcBGAsYHQ/s960/1611015_10204874666607395_3170443930418996314_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-6x9s2NIcT5M/YLdyEzxjJ0I/AAAAAAAAOag/IQgHzUKhzewnpaQ7WrpUgfH3JqS1iFO2QCLcBGAsYHQ/s960/1936728_10205396651736697_1393637145546842917_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-tTek-uIihwg/YLdx7IfUJxI/AAAAAAAAOYs/v0DBKdW-hs4ZORwRn0n4jy7AM8Yb3nqqgCLcBGAsYHQ/s960/11951974_10204809510778540_5914217347868539884_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-y3gCWQW3YGI/YLdx63w8MxI/AAAAAAAAOYo/20k1qwbSgvczgkVlKw2bpBlwNa2JeZ_NgCLcBGAsYHQ/s960/11960245_10204825595100638_6289525329554257045_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-417tr6QXW5I/YLdx7Gkuk5I/AAAAAAAAOYw/Zym1fV22BZMtIqLy9b6R-GHAzsYgQlONwCLcBGAsYHQ/s960/12004009_10204948119523672_58312134473681252_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-XBD01Ns6BuM/YLdx8Mg8N1I/AAAAAAAAOY0/mKsTflnZkEARRSt20ZmnzPtTDhNfh_l5gCLcBGAsYHQ/s960/12079182_10205115043696672_8025796057169345797_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-vbdBj6fgLL8/YLdx8buHSEI/AAAAAAAAOY4/zIY6o71E_JMn43lS5013KquAFW7G5S-QgCLcBGAsYHQ/s960/13000082_10206228710937657_6494041473619431702_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-2zm_092_8lw/YLdx8t1ITpI/AAAAAAAAOY8/iZ2DpMSNAk0Va7gqfdLW5nZMk_Y7KFbKwCLcBGAsYHQ/s960/13015237_10206259151938663_5938061722087542172_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-AszLJ8Hxr3A/YLdx9hs7uEI/AAAAAAAAOZE/ulXcxTDHaNAQ8g2BCsvMkXcyyh5Z74t3QCLcBGAsYHQ/s960/13124981_10206354039830801_8604230302842339106_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-rzi5Otm2JSI/YLdx9yBSQtI/AAAAAAAAOZI/2jVa73o3LMANLoVOQ1K4hzbn_DtNupKpACLcBGAsYHQ/s960/13165895_10206384028100489_1096942453384671628_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-ygi2516sKV4/YLdx-XrI0DI/AAAAAAAAOZM/2J-LLJEY2rk_wJYMYZwxi6qlM1vbB8zkQCLcBGAsYHQ/s960/13177473_10206416663036342_6961646612218322795_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-r4yMOi2rF4Q/YLdx-qk9sjI/AAAAAAAAOZQ/ddMXShekVcIoy2DET0z4FTv1bvxnMnlBACLcBGAsYHQ/s960/13178906_10206416448110969_6082789204609505005_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-iS2mIIHk-jY/YLdx_erBuSI/AAAAAAAAOZY/tWv0ntMahkErAtY1CkBPiRQJUQCYVeM8wCLcBGAsYHQ/s960/13260175_10206471138678199_8296549780003990346_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-zh37ZBWgaZM/YLdx_kjgbfI/AAAAAAAAOZc/zBcq3XQ0t0M2KjKKLRyWOhWSXsJp1iScQCLcBGAsYHQ/s960/13266122_10206490708767439_7894413766419939243_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-x8bY9UECrvE/YLdx_xYgcEI/AAAAAAAAOZg/DjTNOmWy6lUERC1by-6PInIORD_-JCAlwCLcBGAsYHQ/s960/13346543_10206588035040535_5294999514815617631_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-abJtcY4VXTA/YLdyAUa9iwI/AAAAAAAAOZk/Hn-HIGZTot0AUxPfN73sEcOMOuSzTsfogCLcBGAsYHQ/s960/13880185_10207064977363795_6521651471995872551_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-Vz1mS-LFA3I/YLdyAk-Jh1I/AAAAAAAAOZo/hlx5d2Udo_E1GgfSR-AHKKDsIz0Zn0MswCLcBGAsYHQ/s960/13902622_10207022845390522_641695155202927963_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-ZgP1TBo5TpA/YLdyA5rhqXI/AAAAAAAAOZs/MckwqBWfgkEiNc9mrnsksTVrvwLBpe2LwCLcBGAsYHQ/s960/13902807_10207030581023908_8052518566845894481_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-VfxPAbt4j8Q/YLdyBYUSvdI/AAAAAAAAOZw/_RKNrl4eccI-L_WJq363jywaNOw2AF1fACLcBGAsYHQ/s960/13920652_10206998638545366_1672876937197820933_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-7tHIvHuLxVA/YLdyBWEMQgI/AAAAAAAAOZ0/EGa9614Wvs0IBgeuzKCrTOIQFrNiga79gCLcBGAsYHQ/s960/13921134_10207003127937598_4712858949200854083_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-FuLC4pEAIMY/YLdyBiC3k1I/AAAAAAAAOZ4/V2Ad9HwcGwgkMFxAJxLheMpJGIx4606RACLcBGAsYHQ/s960/13932921_10207031239760376_2053277867507967232_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/---IX377bg1c/YLdyCL2MGKI/AAAAAAAAOZ8/AeSvBmKJ1rAhHWU06fWVTersKKcgNBJKwCLcBGAsYHQ/s960/14021491_10207107000814355_1028473197877378160_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-lXMun-Kk7sE/YLdyCTSq3_I/AAAAAAAAOaA/_9tC32XlXtcDdX9NXqFIi97anPFtA3wwgCLcBGAsYHQ/s960/14045871_10207069634080210_6097575336131694692_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-iq0nb9Jh5FI/YLdyCoiLwkI/AAAAAAAAOaE/h9lXDY8w4zIA9qQwNajXFxD1MWc5Fr3rwCLcBGAsYHQ/s960/14184389_10207226846050411_1975569787570828073_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-KUohnORrhuM/YLdyDEt1dcI/AAAAAAAAOaI/vUMtkmn1lBcUqcDr2zcc4xJEERfml8I0ACLcBGAsYHQ/s960/14192700_10207251847995444_4172260695919429635_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-wplDQh8OSOE/YLdyDJNp5KI/AAAAAAAAOaM/sPdoGTHfEecABLMwKJtsLWj_6vDF6cZFwCLcBGAsYHQ/s960/14192775_10207251390183999_4587156782589081730_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-cIkS9qTyH1Y/YLdyDTy3eZI/AAAAAAAAOaQ/1S2X4mZ3t0odcd3oqmcpFZE-lJeK2HnCACLcBGAsYHQ/s960/14225532_10207227069776004_7630080746513109688_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-I_1u5fwcC_c/YLdyDz3c5-I/AAAAAAAAOaY/DUM0CPMW8KEi6VUsPaWWEwYLFBZncF0PACLcBGAsYHQ/s960/14233064_10207255723932340_7670941596541821372_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-2JjgM6rnPzc/YLdyD5lTS2I/AAAAAAAAOaU/nof_A6tx4f0KNFUI-07MvA4Ks_vy6QUzACLcBGAsYHQ/s960/14233222_10207225110887033_8917044278941851958_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-WIXTfosQAto/YLd14DRL3iI/AAAAAAAAObM/yfqhdR62D_0NdLNvYsMV7Q9G8HFvWLpKwCLcBGAsYHQ/s2048/12890962_10206146031830731_8653409357574200403_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-1BeYKoKW7t4/YLd13wmYH1I/AAAAAAAAObE/QtSLBXg3bJohoLhFZuYzAa27qgNsJYcIwCLcBGAsYHQ/s2048/14468567_10207405013184478_8540051352358808553_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-O49xwjYx-Oc/YLd138GzEKI/AAAAAAAAObI/PeBQE63Vj4Agj_PJ3GJ-ThIt2E_BRCllACLcBGAsYHQ/s2048/14480467_10207391171958456_7419748018999372263_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-ymi46an3T7A/YLd15AWBRUI/AAAAAAAAObQ/K96ECSs4PfYA9HgdE0idEgE6dxojG5tJACLcBGAsYHQ/s2048/46266456_10213340392965263_8094537258599710720_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-Cm1fivA_DOE/YLdx-2KCdTI/AAAAAAAAOZU/KCk7F36ezgg4c9tO3ahuQeleg28WY9rXACLcBGAsYHQ/s960/13260139_10206508412890031_8757637749690133787_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-NsjYwdtM6tA/YLdx9D0OFXI/AAAAAAAAOZA/rArYaXv1mk0ilu8vC1TaBF8XiVAdMdRwwCLcBGAsYHQ/s960/13087703_10206353363133884_4152557819958208994_n.jpg"
      />
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <p>
        <a
          routerLink="/photography/casual/next"
          class="btn btn-outline-light text-white"
          >Continue to page 2 →</a
        >
      </p>
    </div>
  </div>
</div>
