<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Conceptual Art Photography</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-Pt7PeID56KY/YLfgs9dwVfI/AAAAAAAAO1Q/Aq1mfEqj9OQ9DAVNvN4tlxibHZsp44oYACLcBGAsYHQ/s960/12377665_10205391053156736_180768716340258921_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-4 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-rz2cbvFXDXs/YLfgtBM8AJI/AAAAAAAAO1U/AUcQ086CAiscN4sfWtrL-_R-x8qZ45ZcwCLcBGAsYHQ/s960/12615270_10205633635581145_8013819259540686304_o.jpg"
      />
    </div>
    <div
      class="col-4 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-Mgj1en3nK14/YLfgsDhi5cI/AAAAAAAAO1I/APMIWvFxPOIYqMq52BYh4tSsOf8MmdHzwCLcBGAsYHQ/s2048/12238414_10205227340864031_778181118159969280_o.jpg"
      />
    </div>
    <div
      class="col-4 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-vQSkynvNu1A/YLfgt6vKNvI/AAAAAAAAO1Y/U4sy9HyqBpg6p_IKgUOnNUmZvV-5xeDuACLcBGAsYHQ/s2048/12628357_10205615757294199_3287859292085175463_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-r9KOwLvTDbs/YLfgt_ZEtaI/AAAAAAAAO1c/lS3r88PZf0MGHEaY49fLt4wJ-A-2bX7iACLcBGAsYHQ/s960/12646658_10205678586504890_3437887909146577413_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-ij-NFADGqdk/YLfg4scck_I/AAAAAAAAO3U/d3MLr5KhWe4xWR6mnSqh5hsVFgH2z-6GQCLcBGAsYHQ/s960/886934_10205963623110627_6134047726902412121_o.jpg"
      />
    </div>
    <div
      class="col-3 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-tlT-8lRWp_8/YLfgoizsiBI/AAAAAAAAO0c/Uqvqu8ZKLZMWjcjKuDY1OdfD4In-HIifACLcBGAsYHQ/s960/10259052_10205437588760097_2717209177267941548_o.jpg"
      />
    </div>
    <div
      class="col-3 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-BqA6OVEnGhM/YLfgqIKvAvI/AAAAAAAAO0w/KfKXvPFB8NAY6b3E6rTcyKBNv4XAtGfsgCLcBGAsYHQ/s2048/11057395_10205324039081426_2477446517368751830_o.jpg"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-BxF2nvnyFgM/YLfgquokTKI/AAAAAAAAO00/BOtQVIbgqOM07AbTs-gwuILS7uIt4kEsgCLcBGAsYHQ/s960/11222601_10205275817115907_6015660095802664155_o.jpg"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-eBzOKnjRFL8/YLfgogYn16I/AAAAAAAAO0g/JQZuzwPLAlYdYqeoWJM8U2JszMW3LgvegCLcBGAsYHQ/s1500/10985538_10203755261222960_3434927893115558356_o.jpg"
      />
    </div>
    <div
      class="col-4 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-GF-bDnEgUEo/YLfgpqkvo5I/AAAAAAAAO0o/DX_TxEA8fG050iGVdv9VQR0FCb_DPHj4QCLcBGAsYHQ/s1500/10998893_10203759287723620_1103356185496760963_o.jpg"
      />
    </div>
    <div
      class="col-4 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-REZXuzlKYC4/YLfgp5njEZI/AAAAAAAAO0s/bptYk8Z18O01MbsZc4E3wuhZJ07iU426gCLcBGAsYHQ/s414/11038895_10203777474818286_7880851201234961819_o.jpg"
      />
    </div>
    <div
      class="col-4 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-IYB4hVMe9XE/YLfgrcdxncI/AAAAAAAAO1A/oDvJnsHIX5oml3B6egjbFv7_FsgctSkuwCLcBGAsYHQ/s960/12045526_10205017904788260_5024400723796113770_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-OQldNSgLJmk/YLfg4aB_dvI/AAAAAAAAO3M/u7PHpGq5y6gCYWd934VcsdGjhHa--Q8TACLcBGAsYHQ/s2048/45696712_10213284554129327_2671261631371018240_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-e4LfviYMxpQ/YLfg38FxnjI/AAAAAAAAO3I/lFajI5yJM-YBrFVmyDGIbKROmHZ0XzdkQCLcBGAsYHQ/s2048/44999793_10213237811560792_4583869095799160832_n.jpg"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-d6Tx6yfTr5M/YLfg3qfbVtI/AAAAAAAAO3E/8Lwm3-GSjNAT81AYOSlvvjB3h1ybUT3vQCLcBGAsYHQ/s2048/41118264_10212900056917137_3399841097277505536_n.jpg"
      />
    </div>
    <div
      class="col-3 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-WNDoZeBAwoo/YLfg2gQJJ7I/AAAAAAAAO24/QfEC--b4IHkjMc0em5xWLmlTrHUdLIr_wCLcBGAsYHQ/s2048/29102043_10211685479353457_1428425209760710656_n.jpg"
      />
    </div>
    <div
      class="col-3 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-0QjfDovBmGI/YLfgx6kDHuI/AAAAAAAAO2E/Oy14SsBKjM0gJQxWTB1A6InM2euqqZjJwCLcBGAsYHQ/s2048/15304566_10207948653855155_9091427513408646431_o.jpg"
      />
    </div>
    <div
      class="col-3 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-SvQnKqQK9ws/YLfguPp4uPI/AAAAAAAAO1g/KopCdM-MKoQTRZeaMhcpibq7YYp8tA0tACLcBGAsYHQ/s960/12747314_10205819746113792_4071329317471687622_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-x03EcM3_Rs4/YLfg27666eI/AAAAAAAAO28/49lAWO2IWqo9lKjmqGn1QGqCRvrLuslmgCLcBGAsYHQ/s2048/30725251_10211927917574261_8300192778202120192_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-XwB79CzXTps/YLfg2JQ6YiI/AAAAAAAAO20/dsAXZZnWpp8sGvylkkh3cx_1yfwjvSoQACLcBGAsYHQ/s2048/29063284_10211664458627952_9104710847904612352_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-5qBv3dYfyUU/YLfgw1eNybI/AAAAAAAAO14/IM7Yct45Ickl8klvnq5K6-JZPu3gSrD4QCLcBGAsYHQ/s960/13912328_10207051180018870_6062311790468297098_n.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-r4TFfTz5BO8/YLfgyo8LygI/AAAAAAAAO2M/T_Uqhi9xR302bYK0SKNUhS4_dyxG60ePgCLcBGAsYHQ/s2048/18738324_10209461664159467_8001737908967347405_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-PpAPAPBxQlU/YLfgzx5M5yI/AAAAAAAAO2Y/l9SSHDMATAkpbWixtDxwlpyJZ2yTdA9IgCLcBGAsYHQ/s2048/20863316_10210162091189705_8698226943045654489_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-eD4OnlTxFZk/YLfg0r_FYGI/AAAAAAAAO2k/-NebQx_FLM8viTjAilwkmLSXgIFYBdsNQCLcBGAsYHQ/s2048/24131738_10210886321855019_7362126465139250276_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-lMT2FrIbp5U/YLfg0v0EUlI/AAAAAAAAO2g/QHimgO0aciM-bVdobe-sH9OI6YJ0q6REQCLcBGAsYHQ/s2048/23916441_10210853774761362_141167736894020537_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-vQfly9AiJDU/YLfgySF757I/AAAAAAAAO2I/0JUHeOtSTQ41Itp0jaGNbySy6FSESWgKgCLcBGAsYHQ/s2048/16700468_10208545665380070_4796041696832455054_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-dSy_61g_gp8/YLfgrLzNqhI/AAAAAAAAO08/5C2JUmfVJbAeWPmzKDn9RBDN0d727-2ZACLcBGAsYHQ/s2048/12038792_10205132746979243_6488980825813940242_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-MQrEu5_2kOE/YLfgvkbWP2I/AAAAAAAAO1w/WwpsY19gWgUu2GbAIvA1Sn9fm-JWXlwxwCLcBGAsYHQ/s2048/13072772_10206319387004502_433678663216032552_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-9DEuX7Seg0o/YLfgvsGMOrI/AAAAAAAAO1s/RN7EXk1SXygFss7ou63-vPRwfLanrW3KgCLcBGAsYHQ/s960/13350481_10206574464181272_2131601614210375772_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-qYT9a7uyvBQ/YLfgzZyPReI/AAAAAAAAO2U/eR88HLzN-5k-DP0XPXmG-Gvo5UxgjkaHACLcBGAsYHQ/s2048/19620598_10209744589312419_8127419093321073995_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/--VDzC31e88w/YLfg1vat32I/AAAAAAAAO2w/wHcwaRRySAIdetW58TFVlvctrzlbOQx3ACLcBGAsYHQ/s2048/27907821_10211455634407477_121954832907508005_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-OL7ovtVOrpQ/YLfg1W8NeLI/AAAAAAAAO2s/qH5SLd2BTT4qedRzNE8aFLa30zcGE15NwCLcBGAsYHQ/s414/28164986_10211521919024551_6394966527114041300_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-ESFt7bBMv7U/YLfgr-38tJI/AAAAAAAAO1E/ile23faNmbgVeLrvW506HSX_C9Z1oasEACLcBGAsYHQ/s2048/12183737_10205149414035909_5570788063405870009_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-HqjWv1LfhJk/YLfguxuC5oI/AAAAAAAAO1o/VvVYcNrHU0chMKp_SWQdJ3galSh5CyaqQCLcBGAsYHQ/s960/13048010_10206324354848695_5511166984788700489_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-6YSNrrZncc0/YLfgywYLoKI/AAAAAAAAO2Q/GBgyO-I6a5UTkwaiCPEtcgUgQa1bMgKzwCLcBGAsYHQ/s2048/19143901_10209585925985935_8037758620057583428_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-3 col-md-3 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-E5heZgQjp90/YLfg3ErKFtI/AAAAAAAAO3A/JgOnWzYssDc9UUOSG7D7UE0ORWKR1wQGACLcBGAsYHQ/s2048/36889842_10212502260012463_5910221268149338112_n.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-ZSvocppdcQ0/YLfgv8J8PUI/AAAAAAAAO10/OHJHcO7G5C0LbeMnbx3DoEi_kWOtmzeZwCLcBGAsYHQ/s960/13669245_10206816905962165_2404321230936503570_n.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-2qMQ9aLKsYc/YLfg1JKoCHI/AAAAAAAAO2o/oRojf3O6Xro1AstcEMc39XFnYC4tfSodwCLcBGAsYHQ/s2048/27173474_10211345571695978_3611240783871175646_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-aDvzEJAk31c/YLfg4ttyo7I/AAAAAAAAO3Q/Zbo3gLhtaW4lId25tl5CkBm58UVyQ_rIgCLcBGAsYHQ/s2048/46315244_10213343054791807_943440500583563264_n.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-RGRNs9s7IFI/YLfgxCjiqgI/AAAAAAAAO18/cW6hE4wR-X86HVTT0orNlvUOJAwM6QUxACLcBGAsYHQ/s960/14047341_10207051179818865_5939079252110406530_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-RZDCSVYjdtA/YLfg0FZwKzI/AAAAAAAAO2c/n5yhYzPif6sPmh4zBLIO82S9ZN0M5nzQACLcBGAsYHQ/s2048/23800314_10210853745920641_5760832336760384644_o.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="conceptual art photography"
        src="https://1.bp.blogspot.com/-oHMAjxAGO_E/YLfgxfNmsTI/AAAAAAAAO2A/scFR8-xxe70oBIrFs2OYJBVoJ-ymyGLdQCLcBGAsYHQ/s2048/14138239_10207185317612226_930937547589501765_o.jpg"
        width="100%"
      />
    </div>

    <div class="col-12 col-md-12 p-3 text-center">
      <p>
        <a
          routerLink="/photography/conceptual/darkbeauty"
          class="btn btn-outline-light text-white"
          >← page 2</a
        >
        <a
          routerLink="/photography/conceptual"
          class="btn btn-outline-light text-white"
          >page 1 →</a
        >
      </p>
    </div>
  </div>
</div>
