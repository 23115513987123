import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-darkbeauty',
  templateUrl: './darkbeauty.component.html',
  styleUrls: ['./darkbeauty.component.css']
})
export class DarkbeautyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}