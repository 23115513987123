<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Content Managment System</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        Free download gratis content management system project.
      </p>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <a
        href="https://axcora.com/getaxcoracms/index.php?id=free-template-website-download"
        class="btn text-white shadow rounded"
        target="_blank"
        style="text-decoration: none"
      >
        <p class="text-white">Get Axcora CMS</p>
        <img
          class="img-fluid shadow rounded"
          alt="new content management system"
          src="https://1.bp.blogspot.com/-nnfgdU8v90g/YAbOasVSN0I/AAAAAAAAJ0o/Hq_8GWNeRJ8Dv_N1J3FITSj-kl63XdLTgCLcBGAsYHQ/s320/website%2Bgratis%2Bterbaru%2B%25281%2529.png"
        />
        <p class="text-white">
          New CMS generation without database installation needed, blast fast
          and powerfull on SEO , support with many themes for build your modern
          website
        </p>
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <a
        href="https://www.hockeycomputindo.com/2021/02/new-laravel-blog-cms-free-download.html"
        class="btn text-white shadow rounded"
        target="_blank"
        style="text-decoration: none"
      >
        <p class="text-white">Lavablog CMS</p>
        <img
          class="img-fluid shadow rounded"
          alt="new content management system"
          src="https://1.bp.blogspot.com/-kIzWZ_rFlQ4/YDnHMYfKo8I/AAAAAAAANHo/Re_wW259f8sBC-YkQPFj_rW8iNqKbU38ACLcBGAsYHQ/s1366/laravel%2Bblog%2Bcms%2Bnew%2B%25281%2529.png"
        />
        <p class="text-white">
          New laravel content management system generation , develope using
          laravel php artisan with SEO support, download now and create new
          modern site using laravel blog cms.
        </p>
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <a
        href="https://www.hockeycomputindo.com/2021/03/new-symfony-cms-blog-open-source-code.html"
        class="btn text-white shadow rounded"
        target="_blank"
        style="text-decoration: none"
      >
        <p class="text-white">Symfony Blog CMS</p>
        <img
          class="img-fluid shadow rounded"
          alt="new content management system"
          src="https://1.bp.blogspot.com/-qw1KfsM1DAI/YFDSi5MXILI/AAAAAAAAKBk/EesAhVSWrwkim-fh_VTcdA2D2zf95cBNwCLcBGAsYHQ/s16000/Screenshot_2021-03-09%2BSymfony%2BBootstrap%2BBy%2Bmesinkasironline%2Bweb%2Bapp%25283%2529.png"
        />
        <p class="text-white">
          A Blogging platform content management system build and develope using
          symfony framework no need database installation support with SEO , and
          markdown lang.
        </p>
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <a
        href="https://www.hockeycomputindo.com/2020/10/angular-cms-blog-new-phoneixs-angular.html"
        class="btn text-white shadow rounded"
        target="_blank"
        style="text-decoration: none"
      >
        <p class="text-white">Phoneixs Angular CMS</p>
        <img
          class="img-fluid shadow rounded"
          alt="new content management system"
          src="https://1.bp.blogspot.com/-lPRdZWWqTc4/X36azQvlcBI/AAAAAAAALh0/fAAODXZv2J4lmUXlU87czv-wvTK4C7WPwCLcBGAsYHQ/s1366/Screenshot_2020-09-23%2BAngularBlog.png"
        />
        <p class="text-white">
          Single page application support progresive website app develope with
          angular and integrated with firebase database,lets create your new
          modern web apps.
        </p>
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <a
        href="https://axcora.com/cmssekolah/"
        target="_blank"
        class="btn text-white shadow rounded"
        style="text-decoration: none"
      >
        <p class="text-white">CMS Sekolah</p>
        <img
          class="img-fluid shadow rounded"
          alt="new content management system"
          src="https://1.bp.blogspot.com/-l3IzGZkyDo4/YPDr-RU6SnI/AAAAAAAAP3k/CINv92-LrGMG9NZeTv8C5NhB5hKquqw5QCLcBGAsYHQ/s1260/axcora%2Bschool%2Bcms%2Bsekolah%2Bwebsite%2Bgratis%2Bfree%2Bdownload%2Bsource%2Bcode.jpg"
        />
        <p class="text-white">
          A Php Content management system , develope with native php and mysql
          databased you can develope website using this content management
          system.
        </p>
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <a
        href="https://www.hockeycomputindo.com/2020/09/larablog-laravel-cms-blog-with-seo.html"
        class="btn text-white shadow rounded"
        target="_blank"
        style="text-decoration: none"
      >
        <p class="text-white">Larablog V.1</p>
        <img
          class="img-fluid shadow rounded"
          alt="new content management system"
          src="https://1.bp.blogspot.com/-M3z2KP-WIpg/YAkuDA8cMMI/AAAAAAAAMe0/gp9wofSASJspVBmTdWyxrD-kY2sgRVDQACLcBGAsYHQ/s1366/laravel%2Bangular%2Bintegration%2Blearn%2Bcreate%2Bwebsite%2Bblog%2Bcms%2B%25284%2529.png"
        />
        <p class="text-white">
          Bloging platform content management system using laravel php artisan
          create new modern site with laravel blog cms larablog now.
        </p>
      </a>
    </div>

    <div class="col-12 p-3 p-md-5 text-center">
      <a routerLink="/sourcecode" class="btn btn-light">Back to sourcecode</a>
    </div>
  </div>
</div>
