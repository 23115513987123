<div
  class="uk-cover-container uk-height-normal animate__animated animate__zoomIn"
>
  <canvas width="100" height="30"></canvas>
  <div class="ratio ratio-16x9">
    <iframe
      src="https://www.youtube.com/embed/j-EyRhkbhF0?autoplay=1&amp;wmode=transparent"
      frameborder="0"
      allowfullscreen
      uk-cover
    ></iframe>
  </div>
</div>
<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Photography of Art</a></h3>
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <a
        routerLink="/photography/casual"
        style="text-decoration: none"
        class="btn shadow rounded bg-dark animate__animated animate__zoomIn p-3"
      >
        <img
          class="img-fluid"
          alt="fashion casual street photography"
          src="https://1.bp.blogspot.com/-BeKMMLvMa8E/YLb6Abr1DWI/AAAAAAAAOVo/BeyTZuCTAh0JpUjgSYQsQZ1jgTO1zz2ZACLcBGAsYHQ/s960/11951974_10204809510778540_5914217347868539884_n.jpg"
        />
        <h5><a href="/" class="text-white">Fashion Casual</a></h5>
        <p class="text-light">
          Fashion Casual vintage photography .
        </p>
      </a>
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <a
        routerLink="/photography/beauty"
        style="text-decoration: none"
        class="btn shadow rounded bg-dark animate__animated animate__zoomIn p-3"
      >
        <img
          class="img-fluid"
          alt="beauty photography"
          src="https://1.bp.blogspot.com/-EQbRyqkjr8s/YLb6BY3_REI/AAAAAAAAOV0/nPVB8zO__28Z5ad-5togoAifg9UA5MAhgCLcBGAsYHQ/s960/13083155_10206338047871012_5155540471143450680_n.jpg"
        />
        <h5><a href="/photography/beauty" class="text-white">Beauty Art</a></h5>
        <p class="text-light">
          Beauty retouch and ART photography.
        </p>
      </a>
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <a
        routerLink="/photography/conceptual"
        style="text-decoration: none"
        class="btn shadow rounded bg-dark animate__animated animate__zoomIn p-3"
      >
        <img
          class="img-fluid"
          alt="conceptual art photography"
          src="https://1.bp.blogspot.com/-vMwF_1ePGxo/YLb6AqaINWI/AAAAAAAAOVw/rzQnH3o8WdE5RdcR-KOm7t7cfUS0Bgg3ACLcBGAsYHQ/s960/12096075_10205100591455375_1707643062712218126_n.jpg"
        />
        <h5><a href="/" class="text-white">Conceptual Art</a></h5>
        <p class="text-light">
          High class conceptual ART photography.
        </p>
      </a>
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <a
        routerLink="/photography/street"
        style="text-decoration: none"
        class="btn shadow rounded bg-dark animate__animated animate__zoomIn p-3"
      >
        <img
          class="img-fluid"
          alt="human interest street photography"
          src="https://1.bp.blogspot.com/-Q7aQs80Ze3k/YLb6AT7FiQI/AAAAAAAAOVs/-UEKIULcNVgp6Fs13N41yiR_TzJ4bLfogCLcBGAsYHQ/s960/12743522_10205790406660324_5012248319378927197_n.jpg"
        />
        <h5><a href="/" class="text-white">Human Interest</a></h5>

        <p class="text-light">
          Human interest street photography art
        </p>
      </a>
    </div>
    <div class="col-12 col-md-12 p-3"></div>
  </div>
</div>
