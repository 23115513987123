<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3>
        <a href="/" class="text-white">Photography Beauty of Art - page 2</a>
      </h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-RzWS8Oy67yo/YLfJKSZy4oI/AAAAAAAAOu4/ALSA3GL-k8ASE3-zGxlhBj4DyWyuhAHRACLcBGAsYHQ/s960/14102143_10207167083836393_3046874103006789071_n.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-LhUU2AmNP80/YLfJMQbLocI/AAAAAAAAOvQ/l6vA8sOfNMUICLM47F92dFMcVU0xL2AAACLcBGAsYHQ/s2048/18192719_10209217888465227_3208547843351416536_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-cw9Np5TmElY/YLfJL6-SvFI/AAAAAAAAOvI/nWERqWUKMF83HkcCOEjGXf-2F7-LV2KxgCLcBGAsYHQ/s2048/17635279_10208934707465879_1713583147791764071_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-vqSTc2TaE8g/YLfJMeimFcI/AAAAAAAAOvM/hGaMtAUpw9cprCkoVpodb09HVBhtvwzxQCLcBGAsYHQ/s2048/17880416_10209060841619154_4370299821540924190_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-F91Wy3Doydo/YLfJIwfbFGI/AAAAAAAAOuk/FK6koe1Kam46YMvrjYR_7q0ccwxJmRjjACLcBGAsYHQ/s2048/12495993_10206009240011021_5404687939402271152_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-1D0phxC8h4o/YLfJNB_VYOI/AAAAAAAAOvU/CRrXGvllX5wRKeuhvTsvY4LZyxj3GJ16wCLcBGAsYHQ/s2048/37819863_10212606797865844_1874231098414727168_n.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-AAXoJ2lv5o0/YLfJNrxur5I/AAAAAAAAOvY/zy2LGqHLEcISVLWisRcY9V9MsRXib4m3ACLcBGAsYHQ/s2048/49946575_10213758561139206_5142559502519762944_n.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-0IzKzV7FJDQ/YLfJLQ43LNI/AAAAAAAAOvE/85aobJzUoecjor5Ujn7AwaiZdzee0-OxwCLcBGAsYHQ/s2048/17097377_10208719915896224_7039087120780198281_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-OCiI09RL674/YLfJJ-SQ0iI/AAAAAAAAOuw/a_JvrSFD-QYBEqjLSceqZE_FDFTjRPJqwCLcBGAsYHQ/s2048/12916934_10206167053516260_5874484361447767244_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-Ixix26U0y_M/YLfJI6A0DuI/AAAAAAAAOuo/x18t4aFWB6M6wrXws8ab14t921Elhg3bwCLcBGAsYHQ/s2048/12898186_10206125418995423_7766763653640847598_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-7C0R9BtHc5Y/YLfJLIH29HI/AAAAAAAAOvA/He34dVQkRBYpb5MQr5vlCNRQRTu4_qg0QCLcBGAsYHQ/s2048/15304566_10207948653855155_9091427513408646431_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-NTkgCIdHd7s/YLfJJ_zNx-I/AAAAAAAAOu0/TEPdQuj7ul4uT5EtCAVs6FeUOYGqSpSdwCLcBGAsYHQ/s960/13921115_10206970959813415_2910884623120281160_n.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-zog-GeoAQ0k/YLfJJDERKOI/AAAAAAAAOus/L6aqe4QI7Psjjduu1Dom8Jt01DkMQu26ACLcBGAsYHQ/s2048/12916197_10206134175014318_8889881730310753616_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-aguyNdg8S4A/YLfJHi9kf7I/AAAAAAAAOug/PFoqR_UdlI8QqOZs1BUyrHeaaFQN0YO9gCLcBGAsYHQ/s960/12187983_10205180020401049_8952085953898280492_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-liYfAgN81Fk/YLfJONlcqiI/AAAAAAAAOvg/zqEEB8qW-jsKgl3o8HDwkf8D-Zfx7KAiwCLcBGAsYHQ/s414/980167_10205479424605967_6392343987342303337_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-8p3i9WKSZiA/YLfJHoBOADI/AAAAAAAAOuY/sRahTi9xMZM1e8q2rpUhzlFqyZ5Wu3DrACLcBGAsYHQ/s960/1075586_10205945957348994_6142610791070303792_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-OkilmtmUwCg/YLfJHqdKgSI/AAAAAAAAOuc/5T4R5ZpG6i4DA3mkaOLQhFtoX195YefSACLcBGAsYHQ/s960/11231048_10205270495502870_7829124085017654087_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-ouNOjlPQLhs/YLfJK9u0cqI/AAAAAAAAOu8/dws5_5ZaFKcac5E9rgX2PoQGdX-O_WSogCLcBGAsYHQ/s960/14191905_10207178953013115_179616643238484073_n.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-MuRFp4IFh9c/YLfJN09dktI/AAAAAAAAOvc/pEn8MzrWY4QSRBA-Nk2Mk_dJ6dGoJr4nQCLcBGAsYHQ/s2048/52659807_10214007142353581_6630445201598971904_n.jpg"
        width="100%"
      />
    </div>

    <div class="col-12 col-md-12 p-3 text-center">
      <p>
        <a
          routerLink="/photography/beauty"
          class="btn btn-outline-light text-white"
          >Back to page 1</a
        >
      </p>
    </div>
  </div>
</div>
