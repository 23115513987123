<div class="col-12 col-md-12 text-center fixed-top">
  <button class="btn bg-dark rounded-circle" type="button" uk-toggle="target: #offcanvas-usage">
  <img class="img-fluid rounded" width="60" src="https://mesinkasironline.web.app/img/createwebsiteusingangular.png"
    />
</button>

  <div id="offcanvas-usage" uk-offcanvas>
    <div class=" uk-offcanvas-bar">

      <button class="uk-offcanvas-close text-white" type="button" uk-close></button>

      <h3>My Art Gallery</h3>
      <hr class="bg-white" />
      <a routerLink="/">
        <img class="img-fluid rounded" alt="creative photography website digital art"
      src="https://1.bp.blogspot.com/-ZLAvC62pMXE/YLZ0ePyxx2I/AAAAAAAAOVc/RN-CYlBx39c63m8V_oEQKhTjf_IzvMN9QCLcBGAsYHQ/s2048/49716230_10213671167914430_3007686978012446720_n.jpg"/></a>
      <hr class="bg-white" />
      <a style="text-decoration: none" routerLink="/sourcecode" class="btn btn-outline-dark">Source code</a><br/>
      <a style="text-decoration: none" routerLink="/photography" class="btn btn-outline-dark">Photography Art</a><br/>
      <a style="text-decoration: none" routerLink="/digitalart" class="btn btn-outline-dark">Digital Art</a><br/>
      <a style="text-decoration: none" routerLink="/cinematic" class="btn btn-outline-dark">Cinematic Video</a><br/>
      <a style="text-decoration: none" routerLink="/dubstep" class="btn btn-outline-dark">Dubstep Music</a><br/>
      <a style="text-decoration: none" routerLink="/hireme" class="btn btn-outline-dark">Hire Me Now</a><br/>
      <a style="text-decoration: none" routerLink="/buycoffee" class="btn btn-outline-dark">Buy Me a Coffee</a>
      <hr class="bg-white" />
      <!--
      <a routerLink="" class="btn btn-outline-dark"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"
          fill="currentColor" class="bi bi-code-square" viewBox="0 0 16 16">
          <path
            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
          <path
            d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z" />
        </svg></a>
      <a routerLink="" class="btn btn-outline-dark"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"
          fill="currentColor" class="bi bi-controller" viewBox="0 0 16 16">
          <path
            d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z" />
          <path
            d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z" />
        </svg></a>

      <a routerLink="" class="btn btn-outline-dark"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"
          fill="currentColor" class="bi bi-cart3" viewBox="0 0 16 16">
          <path
            d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg> </a>
      <a routerLink="" class="btn btn-outline-dark"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"
          fill="currentColor" class="bi bi-headset" viewBox="0 0 16 16">
          <path
            d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
        </svg></a>
-->
      <small><a href="mailto:creativebydre@gmail.com" class="text-white">creativebydre@gmail.com</a></small>
    </div>
  </div>
</div>


<router-outlet></router-outlet>
<div class="fixed-bottom text-center">
  <button class="btn rounded-circle bg-dark text-white" *ngIf="isShow" (click)="gotoTop()">
<svg xmlns="http://www.w3.org/2000/svg" width="26"
      height="26" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
    </svg></button>
</div>
<div class="col-12 co-md-12 p-3 p-md-5"></div>
