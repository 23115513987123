<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Source Code Project</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        Free download gratis open source code project.
      </p>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">POS Retail Software Desktop</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-2oC4VHemzDg/XpAlzZKntXI/AAAAAAAAJYM/nFd_HlEkGAEh3luUwMih_x0rn72QEWa-gCLcBGAsYHQ/s1600/aplikasi%2Btoko%2Bgratis.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/04/program-toko-gratis-free-download-full.html"
        class="btn btn-danger text-white shadow rounded col-12"
        target="_blank"
        style="text-decoration: none"
        >Download</a
      >
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">POS Restaurant Software Desktop</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos restaurant gratis application"
        src="https://1.bp.blogspot.com/-R-9oE2hqWWQ/Xo9yQnk8nRI/AAAAAAAAJWU/rHhIv0ewqusbijnJCbLnddBshRU0MHRLACLcBGAsYHQ/s1600/new%2Baplikasi%2Brestoran%2Bgratis.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/04/aplikasi-restoran-gratis-download-full.html"
        class="btn btn-danger text-white shadow rounded col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">POS Retail Invoice Software Desktop</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-hEsvoZ_3-dU/XejjmsBTGkI/AAAAAAAAJnw/6yq7cWL7ICUpFauV_Ur9c9paz_LmmW0kQCLcBGAsYHQ/s1600/1.software%2Bkasir%2Bgratis%252Cprogram%2Bkasir%2Bgratis%252Caplikasi%2Bkasir%2Bgratis%2B-%2Blogin.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2019/12/software-kasir-gratisprogram-kasir.html"
        class="btn btn-danger text-white shadow rounded col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">POS Wholesale & retail source code</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-AK4vokrqW6M/XfdE223qWrI/AAAAAAAAIYY/XdlvcOBJsqI42z2ualQo8jFZOKpY-u_-gCLcBGAsYHQ/s1600/2.%2Bmenu.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2019/12/open-source-point-of-sale-free-download.html"
        class="btn btn-danger text-white shadow rounded col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Source code Axocrapos Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-bGFS1ExwbXg/YGlab1LVn5I/AAAAAAAANPo/SKa8Wwf-pBwF43CliOShZoZx_gqhoxFcACLcBGAsYHQ/s1350/2.mesinkasironline.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/04/free-download-app-point-of-sale-online.html"
        class="btn btn-danger col-12 text-white shadow rounded"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Toko Point of sale apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-ZWY32FiadvA/XtxNSyCKflI/AAAAAAAAJ2E/iSiVWn9DknUnLjvs_mxNGrv69R-RFLECwCK4BGAsYHg/d/menus.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/06/gratis-aplikasitoko-online-topos.html"
        class="btn btn-danger col-12 text-white shadow rounded"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Laravel Point of sale Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-bDIYL341SWE/Xv_Yfgf9dxI/AAAAAAAAKrU/l2oe1S85PSU7F5xoCLzVpKkeWf5LWok2wCK4BGAsYHg/s1349/laravelpos-freesourcode-aplikasikasirgratis%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/07/free-point-of-sale-laravel-pos-source.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Laravel Stock Inventori Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-Syp4iR0yims/Xz0cZsewXDI/AAAAAAAALPk/Tx-nUnXUe4k-482pQIeTYQ6ggIrqoAM9ACLcBGAsYHQ/s1349/free%2Baplikasi%2Bstok%2Binventori%2Bopen%2Bsource%2Bcode%2Blaravel%2B%25283%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/08/free-download-open-source-code-gratis.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Php Stock Inventori Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-sLFUjphyYOg/YPD30AATC1I/AAAAAAAAP4A/2IF6tvMPPQY5KjXZ_ehqOiKPC7aFbkFrACLcBGAsYHQ/s1140/aplikasi%2Btoko%2Bstok%2Binventori.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/09/inventori-apps-free-download-source.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Stock Inventori Node + Express Js Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-p4THfIB3JZo/X2-EDVrdA3I/AAAAAAAALds/iuNmyJtLrnUXoHAaeUu-bxP9FeI6OEX5wCLcBGAsYHQ/s1366/Screenshot_2020-09-08%2BInventori%2Bmanagement.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/09/program-toko-stok-gratis-download-node.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">New Laravel POS apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-dlhPqvGd9AE/X3LjBn9dQNI/AAAAAAAALgE/SsL3ZTG7Wy8gq_epg4HE3me2H_c6kFyCgCLcBGAsYHQ/s1349/Screenshot_2020-05-27%2BINVOICE%2BONLINE%25285%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/09/aplikasi-toko-gratis-download.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">BoosterPOS apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-cjp0InnAzJo/XlxyvYfo0aI/AAAAAAAAJOM/N2aRPDkbAowbxky6ulpr66l_5IAHvjR_gCLcBGAsYHQ/s1600/onlinemesinkasir%2B%25285%2529.jpg"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/10/ini-dia-aplikasi-toko-gratis-untuk.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Angular POS apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-eHZypwM7CcM/X6sy2F5V9CI/AAAAAAAALmU/dR3yY82HqPsyfl5OfDSIyTbn1tZW4JjJwCLcBGAsYHQ/s1142/angular%2Bpoint%2Bof%2Bsale.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/11/free-download-angular-pos-open-source.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Ninja POS Desktop</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-yayAIXoRt0k/YAGznTbz3QI/AAAAAAAAMaM/c-4J3O6qPegBY6wyBZTogHOknhJTHAFBgCLcBGAsYHQ/s1024/NINJA%2BPOS%2BAPPS.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/01/free-software-pos-download-gratis-100.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">React POS Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-TybcTBZxRiQ/YAmy7He3wLI/AAAAAAAAMgk/KUfJRzK46R8_6_QzOh6MAwvAxWh0SMlCgCLcBGAsYHQ/s1349/reactpos%2Bpoint%2Bof%2Bsale%2Bbuild%2Bwith%2Breact.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/01/free-download-react-pos-point-of-sale.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Axoora Cashier POS Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-uh8dSf2WDVs/YBGYNaCcEvI/AAAAAAAAJ80/7pWDuCJNNZ0UKX8KQTrBwX-i6kcJbgrQwCLcBGAsYHQ/s16000/free%2Bdownload%2Bopen%2Bsource%2Bcode%2Bpos%2Bpoint%2Bofsale%2Baplikasi%2Bkasir%2Btoko%2Brestoran%2Bgratis%2B%25282%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/01/axcora-cashier-apps-free-download.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Riot Website + Inventori Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-Vdo9kEn8m40/YBqiRN16D7I/AAAAAAAAMrM/q_9J0Mbtb6Qdq8_FJ-yhnjXXbQzxHzQTACLcBGAsYHQ/s1348/create%2Bsingle%2Bapp%2Bfeat%2Binventori%2Bapps%2Bfree%2Bdownload%2Bsource%2Bcode%2Bgratis%2B%25283%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/gratis-free-download-modern-website.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Unicenta Angular Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-Su02_qp2xZg/YDZ_X7nWoSI/AAAAAAAANFk/eXr1yLj90aoJlRYHYiuemzaJg-Wsqt1xQCLcBGAsYHQ/s1366/unicentapos%2Bonline%2Bweb%2Bapps%2Bapk%2Bandroid%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/unicentapos-online-web-app-apk-android.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Unicenta React Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-LQPEk12RtBc/YDdfun-KlvI/AAAAAAAANHA/cXNhRAfIRHEoAjXBfnFiK6l4kAUniLlbQCLcBGAsYHQ/s1366/unicenta%2BAPK%2Bandroid%2Bonline%2Bweb%2Bapp%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/unincenta-opos-web-app-react-include.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Cool POS Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-VE6KE68p5NQ/YKS-xIIOLxI/AAAAAAAAOCk/vcCg87IfuucUKgrQ8P3_80kml2nG_5fUACLcBGAsYHQ/s1349/aplikasi%2Bkasir%2Btoko%2Bgratis%2Bdownload%2Bnew%2Bfree%2Bsource%2Bcode%2Bfull%2B%252813%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/05/new-source-code-pos-free-download.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Restaurant Waiters kitchen Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-kcAcCJR3vr8/YKkoyoEiyjI/AAAAAAAAOEk/7RORW49cKpwKZOLPFG9YAsr6_urHDRy4wCLcBGAsYHQ/s1366/restaurant%2Bsource%2Bcode%2Bpos%2Bfree%2Bdownload%2Bgratis%2B%25285%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/05/restaurant-pos-source-code-free.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">E-Learn Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-zc50aOG3P44/X8o9ft70npI/AAAAAAAALn0/Xgr_lTO2R2El6ZrfUmpHctngaYqDji81wCLcBGAsYHQ/s1828/aplikasi%2Belarning%2Bsekolah%2Bsd%2Bsmp%2Bsma%2Bonline%2Bgratis%2Bdownload.jpg"
      />
      <hr />
      <a
        href="https://axcora.com/e-learndownload/"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">React Todo Counter List</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download source code pos gratis application"
        src="https://1.bp.blogspot.com/-utO-j_ryNFA/YDXvH6IhJ2I/AAAAAAAANE0/bUfVdJB37kkJepshhqDOgkoM03AzSLVkACLcBGAsYHQ/s1349/Screenshot_2021-02-24%2Bhttps%2Breact-todo-counter%2Bstackblitz%2Bio.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/create-react-todo-list-counter-web-app.html"
        class="btn text-white shadow rounded col-12 btn-danger"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div class="col-12 p-3 p-md-5 text-center">
      <a routerLink="/sourcecode" class="btn btn-light">Back to sourcecode</a>
    </div>
  </div>
</div>
