<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Website Themes Project</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        Free download gratis website template themes project.
      </p>
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Next JS Docs Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-942LuVNEA6k/YLTP9MEZfsI/AAAAAAAAOU8/zEB6vLqXU-UzR3lHocOcSPiNMlVuJNklwCLcBGAsYHQ/s1348/free%2Bwebapp%2Bthemes%2Btemplate%2Bnext%2Bjs%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/05/create-website-using-next-js-free.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Next JS Blog Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-JTHgHXXRnDE/YLTBU7Xc-vI/AAAAAAAAOUk/tpJlfrJPZPoiNU7UkNPMrnYugUCS1MxFQCLcBGAsYHQ/s1348/Next%2BJS%2BBlog%2BThemes%2Bfree%2Bdownload%2Bgratis%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/05/next-js-blog-template-free-download.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Next JS Docs Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-8pjcjAcRQMo/YLR_YKzdl8I/AAAAAAAAOTw/e5mgxlCp5-8uhD0OGupUF_82G5Do7Wj6wCLcBGAsYHQ/s1348/website%2Btemplate%2Bthemes%2Bfree%2Bdownload%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/05/free-download-website-doc-themes.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Symfony markdown blog Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-D5Wj4s9jdBM/YFDSiReAUWI/AAAAAAAAKBc/64NCV0h4qowxGVrkx35IIYT4l1koOQjZgCLcBGAsYHQ/s1948/Screenshot_2021-03-09%2BSymfony%2BBootstrap%2BBy%2Bmesinkasironline%2Bweb%2Bapp%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/03/new-symfony-cms-blog-open-source-code.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Laravel With blog landing page</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-1zTCT6lYwjQ/YDnHNsGEzOI/AAAAAAAANHw/XhvCObSma2wGjp952haCrPPYXjVYJxgHgCLcBGAsYHQ/s2730/laravel%2Bblog%2Bcms%2Bnew%2B%25284%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/new-laravel-blog-cms-free-download.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">React Mobile Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-PMXaMOgWuSw/YCvqkcrIcyI/AAAAAAAAM3c/24biEneGpmI9zK3Rnx3-ifyW0xuNCaKdACLcBGAsYHQ/s1348/new%2Breact%2Bwebsite%2Btemplate%2Bfree%2Bdownload%2BSEO%2B%25287%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/new-modern-react-website-template.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">React Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-b_OQ4-rEvEc/YCnv1cHcT2I/AAAAAAAAJ_k/LLjixtUVTwc2ua6HzETLD2a3MSc6GlkugCLcBGAsYHQ/s16000/free%2Breact%2Bwebsite%2Btemplate%2Bfull%2BSEO%2B%25283%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/new-react-seo-website-blog-free.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">React Bootstrap Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-p7zqhB8x_x0/YCabIdBieZI/AAAAAAAAM00/pBp3Kb4_0rsO6E_ZSQa6FQX62NpxXsRSwCLcBGAsYHQ/s2564/free%2Breact%2Btemplate%2Bthemes%2Bwebsite%2Bfor%2Blearn%2Breact%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/bootstrap-react-free-download-website.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Vue Super landing page</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-jXk_qpmr2KA/YCUvTN4zRXI/AAAAAAAAM0Y/kQeHy6a40NwMFKsk1e1eiRpO6WBM43evQCLcBGAsYHQ/s1784/vuelandingpage%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/super-landing-page-bulma-vue-themes.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">React Online Shop Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-579yTevNjdY/YCKyxxuW7QI/AAAAAAAAMzc/E_KaRBwabLYfR3K4wxps50D5NPOqNdNGACLcBGAsYHQ/s1349/free%2Bdownload%2Breact%2Bwebsite%2Bthemes%2Btemplate%2Bgratis%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/react-online-shop-free-download-source.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Dark mode react Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-7d98Txrtaeg/YCAuCD6VToI/AAAAAAAAMxE/fMPOmckf2JUFrOqg8iwO3ouGR3XbP4nqQCLcBGAsYHQ/s1349/react%2Btypescript%2Brouter%2Brouting%2Bwith%2Bsource%2Bcode%2Bfree%2Bdownload%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/free-download-react-typescript-simple.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Simple React Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-Zj5M1EGw6rs/YB-Oo90WuUI/AAAAAAAAMwk/pEKth3zvFxAtiDznMsgFlOT1vZyA6I9EwCLcBGAsYHQ/s1349/free%2Breact%2Bhtml%2Btemplate%2Bdownload%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/react-website-template-free-download.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Dark mode React Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-hGDFbfhw0aw/YDUgth5cLhI/AAAAAAAANEU/u9RtvMEoRoETA4w88ee17wnT8GQQdAgnwCLcBGAsYHQ/s1384/free%2Breact%2Bdark%2Bmode%2Bwebsite%2Btemplate%2Bthemes%2Bsource%2Bcode%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/react-dark-mode-website-template-free.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Simple React Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-yPEgl15sezk/YDJso6zSusI/AAAAAAAANCY/Nizver3iLfgnujAvLW6pErOzvl69ybXLgCLcBGAsYHQ/s1412/react%2Bwebsite%2Brestoran%2Btemplate%2Bthemes%2Bfree%2Bdownload%2Bsource%2Bcode%2Bfull%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/restoran-website-template-themes-react.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Mobile React Web for APK android</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-OBO-Qjrfty4/YDFUUF9PZcI/AAAAAAAAM_E/VAwIE8ylwaIwtjeZpejisja-_bFZKnC2QCLcBGAsYHQ/s604/react%2Bapk%2Bandroid%2Bfree%2Bdownload%2Bsource%2Bcode%2B%25284%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/how-to-create-android-apk-with-react.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Angular Dark Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-QmLHzPwN8vU/YB6btjrd0cI/AAAAAAAAMug/5xP-xxmVpts7RK8Gp1armzQI8_MjdtJ2wCLcBGAsYHQ/s1349/free%2Bangular%2Bwebsite%2Btemplate%2Bthemes%2Bmodern%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/free-download-angular-template-themes.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Riot JS Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-qYktMJT8C6w/YB1VvdM1uLI/AAAAAAAAMt4/m5PFvckRf2YhzOcGGgvUhYMR0HHy1VG6wCLcBGAsYHQ/s1348/build%2Bsingle%2Bpage%2Bapp%2Bwith%2Briot%2Bfull%2Bsource%2Bcode%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/how-to-create-single-app-website-using.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Blazor Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-0KjkLkqv_mQ/YBvzi5jH06I/AAAAAAAAMtA/yx9VfSrJ_q4zmEG2___jeBytih5eHYonACLcBGAsYHQ/s1366/modern%2Bwebsite%2Bblazor%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/build-develope-modern-website-using.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Node JS blog Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-eJyI6MUCgxM/YBhXYuQiBkI/AAAAAAAAMqE/0ryPJ8PHYxIRxnufowJRkjDP95sRaAegQCLcBGAsYHQ/s1349/node%2Bexpress%2Bblog%2Bcontent%2Bmanagement%2Bsystem%2Bcms%2B%25286%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/02/node-x-node-express-cms-blog-new-and.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Mobile Web Apps</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-ZEj0aiZJXtw/YBUUf2iJ2OI/AAAAAAAAJ94/kgAaBw3J-cs8pOV7fdGfb5FH3gCRK3s0gCLcBGAsYHQ/s1349/mobile%2Bweb%2Bapp%2Bwebsite%2Btemplate%2Bpos%2Bpointofsale%2Bapplication%2Bfree%2Bdownload%2Bsource%2Bcode%2Bfull%2B%25282%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/01/mobile-web-app-free-download-source.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Ionic Angular themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-71XJG_gNypw/YAwN1UpLZmI/AAAAAAAAMhw/TtYJA38yOSI2arDVbbRcIq-CVYoILO4bACLcBGAsYHQ/s660/ionicapps%2Bfree%2Bdownload%2Bsource%2Bcode%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/01/build-website-ios-app-apk-android-with.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">React typescript ionic themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-AyIiNfcalg4/YAzty9uRIPI/AAAAAAAAMiY/zcHZkssMdbU7qqIHaz3ZsIsDHcfGSvOpgCLcBGAsYHQ/s660/free%2Bsource%2Bcode%2Breact%2Btypescript%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/01/how-to-create-make-website-ios-app.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">React Blogger Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-HnTaRXtvkX0/YLxfnt_IZZI/AAAAAAAAO-o/TlQ1yd3uZzwhmNXGZeTQLMd_ktXPmanYgCLcBGAsYHQ/s1349/free%2Bdownload%2Breact%2Bwebsite%2Bthemes%2Btemplate%2B%25285%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/01/create-react-for-blogging-ngeblog.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Laravel Angular Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-dtO6fVEYj0M/YAkuCPdlTmI/AAAAAAAAMes/pqxbEACLvgs7LgeI22wOewl57HiLCejawCLcBGAsYHQ/s1440/laravel%2Bangular%2Bintegration%2Blearn%2Bcreate%2Bwebsite%2Bblog%2Bcms%2B%25281%2529.png"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2021/01/cara-integrasi-laravel-angular-learn.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Angular BLog Web themes</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-U7rUuk6SdcM/X3MEc2N7sxI/AAAAAAAALg8/IFHfSebKqW0HzIwsMwEHmp6K1aTEJViugCLcBGAsYHQ/s2048/Screenshot_2020-09-23%2BAngular%2BBlog.jpg"
      />
      <hr />
      <a
        href="https://www.hockeycomputindo.com/2020/09/angular-blog-new-modern-with-seo.html"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div
      class="col-6 col-md-8 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <p class="text-white">Collection All themes free axcora cms</p>
      <img
        class="img-fluid shadow rounded"
        alt="free download website template themes"
        src="https://1.bp.blogspot.com/-KTDau-Nn_78/YLi5AQ-gQ7I/AAAAAAAAO34/gZwYja255sUV5om4dFyj2NN769cNTseiQCLcBGAsYHQ/s1920/template%2Bwebsite%2Bfree%2Bdownload.jpg"
      />
      <hr />
      <p class="text-white">
        Axcora cms is a open source code from simple cms to build and develope
        modern website using this cms, no need database installation , select
        your themes and just download and upload on your host.. Boom.... your
        site is live now... axcora with page management article content, file
        management , website setting for SEO and more.. features with SEO
        automaticly generate title description meta tag and twitter facebook
        card.
      </p>
      <hr />
      <a
        href="https://axcora.com/getaxcoracms/index.php?id=free-template-website-download"
        class="btn text-white shadow rounded btn-danger col-12"
        target="_blank"
        style="text-decoration: none"
        >Download
      </a>
    </div>

    <div class="col-12 p-3 p-md-5 text-center">
      <a routerLink="/sourcecode" class="btn btn-light">Back to sourcecode</a>
    </div>
  </div>
</div>
