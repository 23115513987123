<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Photography of Art - page 2</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-hGR7JcGX6yE/YLe0iXQXZJI/AAAAAAAAOno/xQ-KXJQu02sxw1fE7pMiGIqcjQw_hpvoACLcBGAsYHQ/s2048/50576456_10213765641116201_5268376515006955520_n.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/--jgOvLy5zwM/YLe0h-EiTQI/AAAAAAAAOnk/k2TZ7hwgBjY7f3yUAaywSA7Uen2Lt415gCLcBGAsYHQ/s2048/50396386_10213795573024480_2051192396779094016_n.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-_rwAsx--xU8/YLe0eyCQd_I/AAAAAAAAOnE/jT-x63_gFLols1K_-9Wvn9ZBSPAGOxStwCLcBGAsYHQ/s2048/26240673_10211203273618615_664746304101204524_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-FYXTh2Dhh40/YLer-rAG2fI/AAAAAAAAOmk/IC9PMPbVczA5HC3lrj0q3anBi4Kl0msXACLcBGAsYHQ/s2048/17436274_10208879039554216_2655411005366421640_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-d-Yvm0znsbQ/YLerih3SFVI/AAAAAAAAOl0/gehK_RC4wGUq7-dXvvIdpL-c1sHb2UJ0ACLcBGAsYHQ/s2048/16903538_10208643498705842_8380305393202413436_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-nuKoeLE-6IM/YLerifZUyII/AAAAAAAAOls/1iRCNoRrZhQE4IuUD2Cs3MTG9UZ-Bum0ACLcBGAsYHQ/s2048/10551636_10202759132000352_4701902087079524021_o.jpg"
      />
    </div>
    <div class="col-12 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-FP_e5TCKxOY/YLer8iHkvZI/AAAAAAAAOmQ/fk_6-OfPryM9xCpB7W-MWneD0Lbzh0rMQCLcBGAsYHQ/s960/15056349_10207795760632920_3548747654305109957_n.jpg"
      />
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-x4JhzTDqS94/YLe0j_dg4nI/AAAAAAAAOn8/3z2cdwIBwGgQkPW9JoAT5aVMHydxVzs0wCLcBGAsYHQ/s2048/74310980_10215889562092898_303502936258904064_n.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-WajPVEr5rto/YLe0mcmR4FI/AAAAAAAAOoc/XO5PrUiDUh0l9wCuYFek9sn9eesaUG_CACLcBGAsYHQ/s1778/76760060_10215889483170925_2346885776823812096_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <br />
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-T_GAxfvALPk/YLe0l5i8JyI/AAAAAAAAOoU/ME6ShnMsWsUjLZrNKPmDqwdkI_Lm1FtOQCLcBGAsYHQ/s1875/75565680_10215889559972845_4367480732096921600_n.jpg"
      /><br /><br />
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-DET9ihw86YI/YLe0jYq9BVI/AAAAAAAAOn0/k9DlOOUZJNsnX_K2nRF2CWvKW2x9lXnDACLcBGAsYHQ/s1880/73210819_10215889576053247_5866768474130350080_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-G_XjsONYyKM/YLe0hE3bTBI/AAAAAAAAOnc/FOOnhkZXnakr5uAMyi8E5075DB94m6RfQCLcBGAsYHQ/s2508/49106258_10213601859101753_6678645102711144448_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-udS4Dd4PT5A/YLe0ghdbEBI/AAAAAAAAOnU/D874eOM8qiAj7eu9774fLn6WDzWhV5WrQCLcBGAsYHQ/s2048/48935144_10213601965784420_9149848523234082816_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-UP6zDnNdAr8/YLe0g_imfqI/AAAAAAAAOnY/UJlvQDPW8a0eDKHTtxV_nKz-3MG5zd3rgCLcBGAsYHQ/s2048/49082972_10213621954924136_2015809584316284928_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-FVsDxFa2XXw/YLerimThkwI/AAAAAAAAOlw/Dvk86Iip1V4v-DwBjgjzTfsvYYp7scidgCLcBGAsYHQ/s2048/16601872_10208565361792468_9120109248385207920_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-vDYwOBW2T4A/YLerjkdFQkI/AAAAAAAAOl4/gkYvXhk-Q9wZEjGv_Vy06xKdOUWYzZ2qgCLcBGAsYHQ/s2048/16903563_10208667650509622_1230591775852822697_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-VJclJt4aQn8/YLerj5L1x0I/AAAAAAAAOl8/UB2ECzVBVGwBCj6XeEFfD-lawbpKdexbQCLcBGAsYHQ/s2048/17388919_10208878297575667_3046155581019261081_o.jpg"
      />
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-xnWKfHW2ZqE/YLe0ehLpFjI/AAAAAAAAOm8/wA-4RBWMQXYxtYO9NjrKS7ndrgKKqEALwCLcBGAsYHQ/s2048/25182411_10210983944335520_3295069493417337011_o.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-ig-y1iYBMG4/YLe0iyVLFgI/AAAAAAAAOnw/WtQlSWlwyRUjR2XYTw7BAiONG74SNsVtwCLcBGAsYHQ/s1875/70187656_10215889556812766_9092855939303735296_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-yOqq1Dx5N20/YLe0kVASP4I/AAAAAAAAOoE/OeasVJa1FiYAbatfhaAkbJCfEeZTVcodACLcBGAsYHQ/s1875/75278273_10215889559252827_1983978228770930688_n.jpg"
      /><br /><br /><img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-SGhNcdARfbQ/YLe0jqk9HwI/AAAAAAAAOn4/2TfcL2kENBcQXWqNzOrXwJrQT0T3T6aWgCLcBGAsYHQ/s1875/73381351_10215889563492933_7458333429604548608_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-CN07da6XTmU/YLe0nUzH69I/AAAAAAAAOok/-NBHj9u1Bhg6DJlS9YwuAB56ZY9InFNXwCLcBGAsYHQ/s2048/76914274_10215889560972870_4107819975887028224_n.jpg"
      /><br /><br /><img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-082Ya0zx4Do/YLe0m2ZQ8_I/AAAAAAAAOog/a9NRI0ulODYbCvXBeGlbkQiRcguXxQfkACLcBGAsYHQ/s1875/76776591_10215889562612911_1101096135700774912_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-9mhxcw2iJo8/YLe0ngBj36I/AAAAAAAAOoo/-vtsGa9-PQADux4JBXoVmL7xU4mfVwnTACLcBGAsYHQ/s1880/77193555_10215889576973270_2281445030876938240_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-LGmyXmIGnHY/YLer9jVOyCI/AAAAAAAAOmc/4gRtc-rAaU8aOoadlVdnfzEUSVDeV1qPgCLcBGAsYHQ/s2048/15443052_10208062774548101_2968818464064268832_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-d125y3ydydk/YLer-LdhB2I/AAAAAAAAOmg/pVbsplcZa5w_cym-uYkGpkiKDbaNM67swCLcBGAsYHQ/s2048/16403142_10208468440009484_5520286511755981784_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-lwLCLN0MMY8/YLer8qWF84I/AAAAAAAAOmY/CTvuBIBITiQ7qASXRP495sTEvtXtZfhLACLcBGAsYHQ/s2048/15384446_10208014044209873_5258490088255209760_o.jpg"
      />
    </div>
    <div class="col-6 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-hJT6KUNAkL8/YLe0eijf34I/AAAAAAAAOnA/7Kb0lFl0v0gSVVH40rwSxPjZUvd6CLSwwCLcBGAsYHQ/s2048/123602074_10218844770451260_6454028229214190710_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-Ea61qLYfdNk/YLe0k3t1ArI/AAAAAAAAOoI/TIXRcg1fDzcIWFAsFylJYhZrar7b0PRVQCLcBGAsYHQ/s1875/75424507_10215889540412356_9056166876962357248_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-LMoeFkZU9WY/YLe0lb5dY2I/AAAAAAAAOoM/iyB6--TQ62koc2aYHOteuSPBaDSkkA4BwCLcBGAsYHQ/s1880/75464134_10215889577653287_4535534083605790720_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-BFqrz25T0mQ/YLe0mAW-w3I/AAAAAAAAOoY/QWLzUEm-kl0LbG0ADxZu08fD2ZNXu-r0wCLcBGAsYHQ/s1671/76697259_10215889542052397_5934498377516449792_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-entoavtViSM/YLe0gO_aWvI/AAAAAAAAOnQ/UxC5xNVXa5UBQqEJXLIMOex_4PzRPHs0wCLcBGAsYHQ/s1080/47430905_10215543648242370_3235722305178959872_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-CIjY1VMKSiU/YLe0lYzJbbI/AAAAAAAAOoQ/Mki7Gaf_iowYkFMaig_6XDiauvVT6RLeQCLcBGAsYHQ/s1875/75543724_10215889552932669_7053686332807184384_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-o79VXodB8nA/YLe0kH3ywnI/AAAAAAAAOoA/LECxcY9anfIhTIND-RKb_dgNkSKtIo6CACLcBGAsYHQ/s1875/74799601_10215889549132574_8768061836546277376_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-tmBaERm6XYs/YLe0fv5zBTI/AAAAAAAAOnI/zMkQgbMh-EE2O5_tpVLD4dge-d5-FvKCQCLcBGAsYHQ/s2048/34445950_10212264127939310_5706514147575857152_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-qHW4onLqRVo/YLe0ijcrSlI/AAAAAAAAOns/hAxWBjGIXcsa83-Q6_AeCeTyavkg0fB0ACLcBGAsYHQ/s2048/51475074_10213881047361285_1073497006569160704_n.jpg"
      />
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art casual fashion"
        src="https://1.bp.blogspot.com/-IDC4aHTrqUE/YLe0f-Vk8NI/AAAAAAAAOnM/gMXQoXSxfGo3lUoAwEL0SIXJnfbOB6jiQCLcBGAsYHQ/s2048/43097429_10213059657587054_308563782123126784_n.jpg"
      />
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <p>
        <a
          routerLink="/photography/casual"
          class="btn btn-outline-light text-white"
          >Back to page 1</a
        >
      </p>
    </div>
  </div>
</div>
