<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Dubstep Music</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        Hey... let's dance with me 🤖🤣
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="shadow rounded"
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1060965070&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/getstarted-dubstep"
            title="getstarted Dubstep"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >getstarted Dubstep</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="shadow rounded"
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1061000446&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/hurry-up"
            title="hurry up"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >hurry up</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="shadow rounded"
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1060979668&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/dubstep-kejawen"
            title="Dubstep Kejawen"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >Dubstep Kejawen</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="shadow rounded"
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1060982836&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/kepedihan-hati-dan-amarah"
            title="kepedihan hati dan amarah"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >kepedihan hati dan amarah</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="shadow rounded"
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1060978750&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/caberawit"
            title="Caberawit"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >Caberawit</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="shadow rounded"
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1060996834&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/tak-itik-itik"
            title="tak itik itik"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >tak itik itik</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="shadow rounded"
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1060998775&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/nintendo"
            title="nintendo"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >nintendo</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="shadow rounded"
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1060973974&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/petuah-sang-emak"
            title="Petuah sang emak"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >Petuah sang emak</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          class="shadow rounded"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1060970674&color=%23f52ae2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
        >
          <a
            href="https://soundcloud.com/user-406288757"
            title="creativebydre"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >creativebydre</a
          >
          ·
          <a
            href="https://soundcloud.com/user-406288757/tuyul-signal"
            title="Tuyul signal"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
            >Tuyul signal</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9"></div>
    </div>
  </div>
</div>
