<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3>
        <a href="/" class="text-white"
          >Street Photography - Human Interest - page 2</a
        >
      </h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-FIRSXJWTj7w/YLeWRoz09WI/AAAAAAAAOd0/eVjF_wdjKegVRTubH2uOHr51DfQ-Tsl5wCLcBGAsYHQ/s1500/10277395_10201976695439927_1080519256032995583_n.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-vHrTA8ePRLQ/YLefOhgkj9I/AAAAAAAAOk4/EnmNvML_81QR-F1exCRZnPfXaHneCcFoACLcBGAsYHQ/s1500/1522658_10203043863798469_2615714088768507802_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-cfU8W_yH3jg/YLefPFWxCBI/AAAAAAAAOk8/vMs6F9s9vqwzqATSwz0LjXkFYayghhPxACLcBGAsYHQ/s968/1598739_10204278502223658_5545460201878129741_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-bnWKf4o8QNE/YLefPdlYj4I/AAAAAAAAOlE/ehh5iNCxN6IoQRHwVPb6hiTFXCC0Kc01QCLcBGAsYHQ/s1500/1618032_10203043859118352_1387522706040697505_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-2Zeh1Oho8aE/YLefPocafQI/AAAAAAAAOlA/eon5RQnE0h8nrQtUCZpJXJFeW0e-z4QqACLcBGAsYHQ/s1500/1911128_10203680974485838_4725905380477154789_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-p4W7aWFnsbo/YLefCMl9LaI/AAAAAAAAOic/Z_vMoPN42SogoCszMV4xVmDfNtb1ncqaACLcBGAsYHQ/s960/10259877_10201976697599981_1286827288687715458_n.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-2rKTWzDQpU0/YLefCpykIPI/AAAAAAAAOig/JAze2g5-D5QWsNRRu8q3pI_WT_C1AIPwACLcBGAsYHQ/s960/10336644_10201976598917514_4065555859029128820_n.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-UseNZoy7js8/YLefCiCvJsI/AAAAAAAAOik/0JC3EXf9EPoW6ic5GVwiyTZ0yGjWuMbAgCLcBGAsYHQ/s1500/10431392_10203043887519062_8777274342743106695_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-GBn9QtRH-Tw/YLefDDyusGI/AAAAAAAAOio/FXjG4caITv0e1OslQd8841GZ8lEqUstHACLcBGAsYHQ/s1500/10495358_10204371351104822_7858686239476372695_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-VsSGxvV5vO0/YLefDrd4UiI/AAAAAAAAOis/eBN_BctOKbMSpvKspg5kW33fRWFFsBSEACLcBGAsYHQ/s1500/10547830_10203043868118577_8032219047831106632_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-erN1Jh5BTpQ/YLefDgyRWnI/AAAAAAAAOiw/EWd_9wbulQkdevy234dkZLyouK9AVlzaACLcBGAsYHQ/s1500/10636925_10203022995556776_4859135430899880804_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-gWUklLXni4Q/YLefEKiE9JI/AAAAAAAAOi0/wxz090Z12HE4V-nJTxqiKJID-e882UWeACLcBGAsYHQ/s1500/10669137_10203022989236618_3325441977434592720_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-TbUh3E1woAk/YLefEv8EnMI/AAAAAAAAOi4/-xSwTklQTbIzxRGEuaTYnshlXt7w4OClACLcBGAsYHQ/s1500/10733720_10203022986516550_7345249254371064893_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-Pgn6_f6Pcmc/YLefE65EjAI/AAAAAAAAOi8/cAMhZuXWxFMiRJD0COo3zjf3Rt4M68S-wCLcBGAsYHQ/s1500/10818246_10203347794436545_7159783406472792353_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-_NmmFb_T_G4/YLefFUcYjOI/AAAAAAAAOjE/VaiccuquVG4jP495rFRp5KOAKyJy82JfQCLcBGAsYHQ/s1500/11041224_10204154931774474_6716421117506731356_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-YpmqBlMVFto/YLefFwUhqUI/AAAAAAAAOjI/xya3BJQ6R9o5uvhGnFABnoVXgK7nMESEACLcBGAsYHQ/s1500/11071493_10204154930894452_2887172562478103766_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-YqJh7KYgQhc/YLefE7U0pxI/AAAAAAAAOjA/E3T0Go_rUiYKx2I1nKzC7DAdoA_saqFbACLcBGAsYHQ/s1500/10835197_10203347794076536_5032390963511905662_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-OmDe0Ac8YpI/YLefFxUTTZI/AAAAAAAAOjM/U7ajUeN99no4hVtv7DW9YsnRvGRVFyjAgCLcBGAsYHQ/s1500/11133884_10204154930854451_3792444243555337246_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-_n1v5eV0Vb4/YLefGGwRRBI/AAAAAAAAOjQ/6U4RaNaRhU4bET01pPyspaOsNt4WDXs9wCLcBGAsYHQ/s968/11154594_10204129341734739_4865522328708968546_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-So9nR3CMstE/YLefGmrJ-vI/AAAAAAAAOjU/LVfy61B0KjsFGQR1gdV7mCWaPZfFBqPeQCLcBGAsYHQ/s968/11174620_10204156746619844_6387266516321859999_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-BlvcRoW5L78/YLefG9yYtxI/AAAAAAAAOjY/Vb_O6uxxGg4jZ3LVWFWO4MJ6WjHkjyCBgCLcBGAsYHQ/s968/11187224_10204156745779823_5788111759639991643_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-3vtE6w0QusE/YLefHYnK7PI/AAAAAAAAOjc/fQqhLIOS9SMT_k0jtcq3qd1OIllcIEgDwCLcBGAsYHQ/s1500/11194593_10204155039217160_4856600127783261426_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-bxaNXbAydRw/YLefHtiD9ZI/AAAAAAAAOjg/EvoR7l-bc20zYKrD77WGboCnn3D7SWC_gCLcBGAsYHQ/s1500/11194641_10204154932494492_8281441113218048494_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-OmdEM8fHTss/YLefH2wt1dI/AAAAAAAAOjk/LAcoXcMkCCY0WVAmD17u9N8kljiOFa70gCLcBGAsYHQ/s968/11200984_10204129342334754_4457948139765607935_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-0V1UXnUrbBA/YLefIeO7moI/AAAAAAAAOjo/uKCaXWY8HB0vtfPZR_3NlkdFcey4jCBqgCLcBGAsYHQ/s1500/11212645_10204154933014505_1417625964311188146_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-nqmwHn_sqs4/YLefITqzciI/AAAAAAAAOjs/1abzswK7f_kb4eevGy1PnLDSv7Mc7weoACLcBGAsYHQ/s968/11212737_10204156747179858_8317563529966963134_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-FYX-HIntN0Y/YLefIvhUyRI/AAAAAAAAOjw/UFjwDEsXDZQHXjfFA8afKx6V-pb-VV5QwCLcBGAsYHQ/s1500/11243923_10204154929774424_7180861105270676527_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-58mAaFSW0iA/YLefJ7kjriI/AAAAAAAAOj0/TkYksO4cFQMQVuRtizsJTNl1wAUgxFk3wCLcBGAsYHQ/s2048/11705396_10204571922038970_3846171903932443534_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-8dJgZ8NzvRQ/YLefJ3rCiiI/AAAAAAAAOj4/aNSlzlMGPgAHh_xJei_9Zu6JET5ze8IfQCLcBGAsYHQ/s2048/11705396_10204571922118972_3817134040422611802_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-N2FsXDjd7Xs/YLefKGdOQYI/AAAAAAAAOj8/xgbnhoQQV3kmPPwWZiedNn2aokzVfaKWgCLcBGAsYHQ/s1500/11741107_10204626136914308_1346050987500748878_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-ycCT4YPZIuE/YLefK5jk_pI/AAAAAAAAOkA/JBFmVEmTkrw7SJJsAghieKi69pdyATCTgCLcBGAsYHQ/s1125/11754849_10204670920633873_7164467301199208076_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-WhpGrb3WTdM/YLefLJBoylI/AAAAAAAAOkE/By8IuHDEQUANd1m7EO0T5BrEQGNtiJAzwCLcBGAsYHQ/s2048/11942070_10204831737534195_4822690879798825028_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-Km4PN1TP9ls/YLefLXPp3uI/AAAAAAAAOkI/KnUoRl3M7LY_XOc7cUCYqjR8QGRDnnZ9gCLcBGAsYHQ/s2048/11951589_10204917150109456_7727313047257311879_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-quPcrum_-m4/YLefLT1CF6I/AAAAAAAAOkM/_1mmXzoK2pkOt8ifEr_KYp8YmmlzkCFmQCLcBGAsYHQ/s2048/12002408_10205002747369334_4901341027852703058_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-BH0UB1BSNRA/YLefLx0gtAI/AAAAAAAAOkQ/N1WNkVdpJvYVROhSRLT8sZJC_U8X7RJ0gCLcBGAsYHQ/s2048/12017598_10205008138264103_6075441141640214998_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-FDAAhJ7KsR0/YLefMMX9SPI/AAAAAAAAOkU/V5LyLz1s4QMJlkjS6v0jiWJeI8Mmy9ESwCLcBGAsYHQ/s2048/12032731_10204988923143737_604943626907430814_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-KPQKOGePwYA/YLefMatGXCI/AAAAAAAAOkY/VxiFYpCVXnwx4yC4UyyG7-gznG1Xnka7gCLcBGAsYHQ/s2048/12032739_10204991923938755_4273521411883046733_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-N1kEW-W9y2E/YLefM-fQtWI/AAAAAAAAOkc/I8daszfqN_wZZlJrhb1XUy2DVioZa9jIgCLcBGAsYHQ/s2048/12045410_10204988854582023_7397575183986383477_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-chjDutUgXEY/YLefM2VhJlI/AAAAAAAAOkg/zemNRgYNYlo97CejoOoHY7LgFicxTQ20ACLcBGAsYHQ/s2048/12593750_10205613753684110_3283752877831312769_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-dnkNqivYQ5E/YLefNRAXbWI/AAAAAAAAOkk/maObb3Qrp6QC9L8xXJSZ964_XqbW4wYBQCLcBGAsYHQ/s2048/13029549_10206291830755613_9087270207383325841_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-P1fnNEqZt3E/YLefNmitFZI/AAAAAAAAOko/oSi-RYi0db8SGSGrSI85O49UmZYNFfSdQCLcBGAsYHQ/s2048/13403387_10206625918307593_6933020517157855568_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-kTfN10tcoV0/YLefN2cc6WI/AAAAAAAAOks/8Urq60WSQUQ6wgexo_CS716v2HPRSti0ACLcBGAsYHQ/s2048/13415620_10206620055001014_5312146241589314166_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-Y3p6vYZcCj4/YLefOSH-qRI/AAAAAAAAOkw/mkPJ_z7GJdsqwxwGEVjmkLVpesDn7Wh9gCLcBGAsYHQ/s2048/13442666_10206622667546326_5575165093519059037_o.jpg"
      />
    </div>
    <div class="col-6 col-md-3 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="street photography human interest"
        src="https://1.bp.blogspot.com/-jMWdY0L1TyQ/YLefOlX7_BI/AAAAAAAAOk0/j2u6tJkBh3QxSveIxHn2vS6UHFr6LPE8QCLcBGAsYHQ/s2048/13925445_10206980935582803_866216717039241077_o.jpg"
      />
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <p>
        <a
          routerLink="/photography/street"
          class="btn btn-outline-light text-white"
          >Back to page 1</a
        >
      </p>
    </div>
  </div>
</div>
