<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Digital of the Art</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-p86avZVe-kY/YLcDtL5IOPI/AAAAAAAAOWM/vcScngT73SEzKaBpHROW6qEUKUp3PbslACLcBGAsYHQ/s782/10423260_10203961991951099_7409218111643902262_n%2B-%2BCopy.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-eeWJnufFr24/YLcDvMJ46KI/AAAAAAAAOWk/r1Sb_z-iMJwY_pwlWjdv6dzKlOynu1JHACLcBGAsYHQ/s960/11350895_10204907754354568_1821142484147370180_n.jpg"
      />
      <br /><br />
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-aTwNVRDBHv8/YLcDvFCQH4I/AAAAAAAAOWg/fl1Oj8lhlqQsOY-994SQdrSUUtkO39pPQCLcBGAsYHQ/s960/12042681_10205005324793768_1811671595756355728_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-BttQ921-bqE/YLcDvjr1-PI/AAAAAAAAOWo/4ydR18dLreQgHMoM4Ij6iKd7IzEVYY2igCLcBGAsYHQ/s960/12047140_10205075590230360_6976577136533634079_n.jpg"
      /><br /><br />
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-s1AewOwafVE/YLcDyqEOdtI/AAAAAAAAOXM/wHS5moOT6JEASaSv_4DYWEQLHy0z5kZAQCLcBGAsYHQ/s960/13516242_10206710875151461_4276462912653251703_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-ioVaaIddIqs/YLcD0ww34tI/AAAAAAAAOXk/9RTdBvWLyUERqKLsV4QQ5NSPJ4wqG3QjACLcBGAsYHQ/s960/13641057_10206941690881710_3236102612660253068_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-vecmdwI-rz0/YLcD1kUi3xI/AAAAAAAAOXw/B7-y_czfix0ljhrwiksi5VkphI-8UL2_QCLcBGAsYHQ/s960/14202784_10207237241190283_2626476380928802927_n.jpg"
      /><br /><br />
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-hAN6-_VAiOI/YLcD1z1UBII/AAAAAAAAOX0/VrV0OUYsjJoFRFY1um_eREa7iw_YLGKxwCLcBGAsYHQ/s960/14203138_10207228936942682_3597044987583593770_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-aCtUPuI5xDQ/YLcD22MuRKI/AAAAAAAAOYA/RPmCJ70DCqcot4JNF3j2n1WQV1ftLjIAwCLcBGAsYHQ/s960/997068_10204225748024836_891075056089904629_n.jpg"
      /><br /><br />
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-0m8z2-RP2KM/YLcD2Y5hc5I/AAAAAAAAOX8/48lY0V3PiyQ0tVR2UZIJtGFeSVlTBST5QCLcBGAsYHQ/s960/1472907_10204407643852118_2106107413581388847_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-OpMZ_o4jHAw/YLcDtNa1HrI/AAAAAAAAOWI/8j6RM6WqhXkD1Pk4D_zVaBOM7ygSAOYQwCLcBGAsYHQ/s960/10394094_10203991845737425_2854924252224985100_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-8 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-WL5tVLOR2SI/YLcD0NuXH7I/AAAAAAAAOXc/g9ZCMagz9FIwWiIwOJLtCdQcT7XakRt4wCLcBGAsYHQ/s960/13621005_10206825063566100_6854787155446940860_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-D8hUoracZM4/YLcD19rksVI/AAAAAAAAOX4/1J4MWeeekYEglthMqxDxGqsRqzHIxIAHACLcBGAsYHQ/s960/14238183_10207253766683410_6845499245600124512_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-imswXHMgfr0/YLcDy9fzMvI/AAAAAAAAOXQ/_ro_smpXfa0i-x9L0-aHdiuCLRxxVPuuQCLcBGAsYHQ/s960/13524543_10206703084156691_5019143694885788927_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-Ob9lOX5E5Ts/YLcDutk0CUI/AAAAAAAAOWc/2WkkcIv8psMH2Gl5co6UvRXFoTeBunfoQCLcBGAsYHQ/s960/11230651_10204870702508295_7974037716692577327_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-HT0rx62nzks/YLcD1POkGxI/AAAAAAAAOXo/Zu2GB3iILv8qA6LKFhCtBnxG4ahT--wpACLcBGAsYHQ/s960/13775621_10206837967928701_4406082785772027165_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-eDvKZrcCJ7c/YLcD1PnJOhI/AAAAAAAAOXs/LLbd2BZOF0MKTDV3SN1QGkR4e1BIAcGKACLcBGAsYHQ/s960/14054551_10207051178218825_8602650237562101769_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-W9ZzdFLmg8o/YLcDt3GnOoI/AAAAAAAAOWQ/B8Xy9c0zz-IqIoRBeQcqWaDxSlN6kokKgCLcBGAsYHQ/s960/11074324_10204252289688361_17517557150007977_n.jpg"
      /><br /><br />
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-h2g44CAHifw/YLcDvmDoBBI/AAAAAAAAOWs/QbUGyltZiXUCo1lVEBxl6gvIrTjyBfFcACLcBGAsYHQ/s960/12321144_10205382680147416_9175805752426518874_n.jpg"
        width="100%"
      />
    </div>

    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-a870ISB6mC4/YLcDsw72eoI/AAAAAAAAOWE/aWzxanunlG0nCj6Y1aTlLcHLJsm0JX3AwCLcBGAsYHQ/s960/11059612_10204674789490592_7404850067680701033_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-9OjpmqCGW44/YLcDxPTLqhI/AAAAAAAAOW4/KNsOyEBhxcwxAX0OSFam9Dnvxc1FOQOBgCLcBGAsYHQ/s960/12794577_10205921977709518_5436535098069275832_n.jpg"
      />
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-HCNazywWFPM/YLcDueKRjDI/AAAAAAAAOWU/9s5Zx8e9ySQZwaktdSxRfqK07qpPHuDdQCLcBGAsYHQ/s960/11130160_10203968824241902_1704446697852246306_n%2B-%2BCopy.jpg"
        width="100%"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-HN4GRaGAYCM/YLcDvxTrc7I/AAAAAAAAOWw/EF57jsvNh-kXG6lnaLSSSzEodrXEUIdTgCLcBGAsYHQ/s960/12540896_10205628513373093_8360027838684811339_n.jpg"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-f-MZY7oMZao/YLcDxpFYQCI/AAAAAAAAOW8/6cJEffaxl3ceWMLmPSrHdXSD39dC3aHYACLcBGAsYHQ/s900/12803103_10205998206055179_106352576025353442_n.jpg"
      />
    </div>
    <div
      class="col-4 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-G6Ig3TDAMU4/YLcDxzISnoI/AAAAAAAAOXA/-IH8DX9esVUSvQzqP0vZpUr6tkSjClYggCLcBGAsYHQ/s960/13406805_10206589188349367_938707191884813742_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-dzsWXd3j-WU/YLcDwTnJyvI/AAAAAAAAOW0/X4CoJvho17Mt9Vc65O210R4vNxHosa6IgCLcBGAsYHQ/s960/12593783_10206000196624942_9050616884091398150_o.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-_y2voGxKyR0/YLcDuRcgUTI/AAAAAAAAOWY/PH1r2rvYvs8gEUH_T51ljBLIZjX5_sdoQCLcBGAsYHQ/s960/11193409_10204127294603562_2875561234130979445_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-n5bp--fnEBU/YLcDx4H7WYI/AAAAAAAAOXE/JIgpF9dQUxIoDrF6miB9Dgi8gkP0etglACLcBGAsYHQ/s960/13419272_10206645140588138_2543166287042910822_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-b67l0DlTao4/YLcDzu3plvI/AAAAAAAAOXU/HvNHGv4NIJkk52SLFvTb0WsdS8NOnfu0ACLcBGAsYHQ/s960/13537625_10206700963503676_2377927342351563205_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-djGa468CVBU/YLcDz1vgF2I/AAAAAAAAOXY/fUWuHuiSAWcPoVNcrSA7Uq9c4EHWqEozwCLcBGAsYHQ/s960/13614981_10206796059841025_7710079975790305459_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-Ky9AsKKgz-M/YLcD0TkNN2I/AAAAAAAAOXg/tGNM-pgpmUEV9oKdfareGB1fDvvlg6INgCLcBGAsYHQ/s960/13631561_10206803159098502_7571935363917039058_n.jpg"
      />
    </div>
    <div
      class="col-6 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <img
        class="img-fluid shadow rounded"
        alt="digital art photography"
        src="https://1.bp.blogspot.com/-qH9SUHsPPFA/YLcDymYc6DI/AAAAAAAAOXI/b1jX3V9dtQoYjYsX98sJJOvbmanc7Vu9wCLcBGAsYHQ/s960/13507054_10206713718662547_6644315528859008093_n.jpg"
      />
    </div>

    <div class="col-12 col-md-12 p-3"></div>
  </div>
</div>
