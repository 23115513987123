<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Cinematic Video Art</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        Cinematic Video Art teaser
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-21x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/tMyzNWq5fLs"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <div class="ratio ratio-21x9">
          <iframe
            class="rounded shadow"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/N-sVtvY1Lhk"
            title="cinematic video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/cIWRDVHJLqU"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-21x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/7E-uPPqSrVg"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/KZsOBjBz_kc"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/rrmP0njbvX8"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/2v-cgX5XMRo"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/B6RpLTSCz5o"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-4 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/9aRsR-tnryw"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-21x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/EQzccywHUlY"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="col-12 col-md-6 p-3 text-center animate__animated animate__zoomIn p-3"
    >
      <div class="ratio ratio-16x9">
        <iframe
          class="rounded shadow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/esyrVGd-vnM"
          title="cinematic video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</div>
