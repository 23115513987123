<div class="container">
  <div class="row">
    <div
      class="col-6 co-md-6 p-3 text-start animate__animated animate__slideInLeft"
    >
      <h3 class="text-white">Creativa by dre</h3>
    </div>
    <div class="col-6 co-md-6 p-3 text-end">
      <p class="text-white animate__animated animate__slideInRight">
        <i>- Just Man in da Art -</i>
      </p>
    </div>
    <div
      class="col-12 col-md-12 p-3 text-center animate__animated animate__rubberBand"
    >
      <h3><a href="/" class="text-white">Photography Beauty of Art</a></h3>
      <p class="text-white animate__animated animate__slideInUp">
        All photos are creative shoot by dre, buy high resolution photo if you
        are interested
      </p>
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty art phosothoot photography art"
        src="https://1.bp.blogspot.com/-EQbRyqkjr8s/YLb6BY3_REI/AAAAAAAAOV0/nPVB8zO__28Z5ad-5togoAifg9UA5MAhgCLcBGAsYHQ/s960/13083155_10206338047871012_5155540471143450680_n.jpg"
        width="100%"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-3Bjl-3ls-5Y/YLfBxT90k7I/AAAAAAAAOpI/9u2-LIjRTf4OVYfifgaqvTdeL1QyOCO4gCLcBGAsYHQ/s960/1044031_10205958946313710_6411244280684106859_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-bhJbq1OEqDc/YLfCI1ZkmnI/AAAAAAAAOs8/URl8cJYHtroUh4vAiATUe16jvS-YraXjwCLcBGAsYHQ/s960/1901542_10201580024243395_1263060974_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-GvdJypXj2BI/YLfCJQgWXfI/AAAAAAAAOtA/QDNA-VdTwg8wuV0IwDyXcjQE6WksgMEfACLcBGAsYHQ/s960/1918091_10205964067601739_936551991610822203_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-ENj-c8-7rdM/YLfBxeKcelI/AAAAAAAAOpE/OkpZOo2ugRsNyJ9CsSp0skK7j0cPsD9rwCLcBGAsYHQ/s960/12795550_10205894210255349_7852463678127856581_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-BrX_XRzS55I/YLfByUDSDvI/AAAAAAAAOpQ/I4g0lNWx-h0Y8ML1L1rSXNqSBn6UZ3w8ACLcBGAsYHQ/s960/13087684_10206321601539864_8553916293687352005_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-fypfB5I1Dq4/YLfBzF2atnI/AAAAAAAAOpY/d05yDacy6U47WF5yqzaE6OuNrFqh3hwmgCLcBGAsYHQ/s960/13139274_10206411488826990_4240826638011924130_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-xvDm3icBpJQ/YLfBza2Z55I/AAAAAAAAOpc/9qtPD3iedsY9tjm4hQ2cCbM44PLgF-FwQCLcBGAsYHQ/s960/13151629_10206400101982326_6703059564402866127_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-CvX4Y-HuaUg/YLfBz-y3eyI/AAAAAAAAOpk/fsPTdZbufKETxV___c_iHXLoHcV5GUR_wCLcBGAsYHQ/s960/13179196_10206435965758898_8501659387238098824_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-1ReGcUqFbe8/YLfB0QKTx_I/AAAAAAAAOpo/XXgv413_qiEKT7rTM6Y0xLI_PGOtzlF6gCLcBGAsYHQ/s960/13237833_10206495993579556_2798868216571640906_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-bAAY8om4XNk/YLfB0t28rYI/AAAAAAAAOps/CbJEUw29Xb48RMF8OB4SCavKHPc_HNdAwCLcBGAsYHQ/s960/13241131_10206458671526528_4093673633118326507_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-MJDZd_bSHG0/YLfB1s4HddI/AAAAAAAAOp4/J9jMyyHn9KAhBDkLxjx2jqm72M8LjGwxQCLcBGAsYHQ/s960/13263840_10206474485081857_2909242298830994591_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-LiJC0c3WTgk/YLfB12Wg_TI/AAAAAAAAOp8/jJcaA0uVlagPb6cBipCsRrcOkgjc0DvggCLcBGAsYHQ/s960/13267831_10206508411569998_1308880127422847333_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/--f3VQk6BhDI/YLfBy1W_UUI/AAAAAAAAOpU/BVQ9iPQzZtkauJr5P7wA3gJtRNr8oZvxQCLcBGAsYHQ/s960/13139197_10206365538078250_2676256974420810374_n.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-X-jIU2QIIso/YLfBzr9iaMI/AAAAAAAAOpg/1lmUN7Adgz8h0XvrTO2f88NT6PoTFHOZACLcBGAsYHQ/s960/13151837_10206361333573140_2066214076588170352_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-6J38X-VXx9w/YLfCNlx116I/AAAAAAAAOtw/f2REZcNc0I8u4VQLLSiWpHKdwUgLnuE0gCLcBGAsYHQ/s2048/53155020_10214051642626060_2759326452557021184_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-I2xDnY9H9AM/YLfCNPEy_4I/AAAAAAAAOts/SlCv5RD_m-QiExNWOIOj8MNDP1hMfz31gCLcBGAsYHQ/s2048/51855824_10213950548058759_9209447319089446912_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-WuhFDGAUTho/YLfCMQ0Cr6I/AAAAAAAAOtk/Dn8EJspu0n0nZ0hH1cPo3SR8EE_C8bnHwCLcBGAsYHQ/s2048/46488144_10213374771024693_1916114936585519104_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-z5sMqabAino/YLfCMPcvczI/AAAAAAAAOtg/b6EIzHwv4RYwzwjpwj8m55EFh3OSkKDZQCLcBGAsYHQ/s2048/45741466_10213312752834277_1552007129856999424_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-ACVpgi4u7h4/YLfCLTFRVnI/AAAAAAAAOtY/j0NfTG53l1oTVbTe9TdwZu4iBTIm8d8YgCLcBGAsYHQ/s2048/43067692_10213063219756106_5538431933886758912_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-l8LxLOEavJ0/YLfCKti8DVI/AAAAAAAAOtQ/2ouozNvzOxsDyfuB3GKjWNKpqyi2o5v3gCLcBGAsYHQ/s2048/26685358_10211354339715173_2778689855098316495_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-LGS1LL7343U/YLfCLMVEroI/AAAAAAAAOtU/oapvjaOzxgIZkhGTWZi38lLAsYQjQMt6QCLcBGAsYHQ/s2048/29137035_10211685435752367_7371720534997860352_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-9CcZNBPnIkA/YLfCLmvp_XI/AAAAAAAAOtc/sdfK34xfB_YQSdyp3QHkplGQYkk9CvjswCLcBGAsYHQ/s2048/45137769_10213255495962891_8723183199284363264_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-iHrMYDGWSpw/YLfCHx1py1I/AAAAAAAAOsw/4DGvBPEqwToW0-uyl5xuzFKKcAmznbRRQCLcBGAsYHQ/s2048/18814769_10209496670794611_2747870292045675139_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-dALfMw8kLDU/YLfCHIi9HhI/AAAAAAAAOso/H6oNCfQbmO069UTj__0kRod5s0qzMHnmgCLcBGAsYHQ/s2048/18404121_10209305233768805_2572347862777876068_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-O4KcsEfIurM/YLfCF18CPhI/AAAAAAAAOsg/CeYBjQK-bewe5Gs6GeVk1Wp5IY1XrzOPgCLcBGAsYHQ/s2048/18216903_10209258210553254_7501977687134491343_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-MXvqiUSa3WE/YLfCFmCM09I/AAAAAAAAOsc/8fqE0fymfFQ5xtXif-ZKAY5dqcbJVb_twCLcBGAsYHQ/s2048/18193121_10209226949931758_373568886108540932_o.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-Foh-l1jWXsI/YLfCGhOcKNI/AAAAAAAAOsk/aeHbHu3YPDMAcaJWtSHdXbSROufc8kd7wCLcBGAsYHQ/s2048/18403883_10209329842864017_1947787801223790020_o.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-aJNDwoRcECA/YLfCDKB7M1I/AAAAAAAAOsA/8iHCapn8IHI20skh7ulMxjYPdoahYwyJACLcBGAsYHQ/s2048/17620310_10208942926631353_3510595162876744820_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-UkNDxaQtE3k/YLfCDW4uekI/AAAAAAAAOsE/ZMQe44ELYUMg4v4e4aRgjJIxzxd6ggqwgCLcBGAsYHQ/s2048/17621776_10208940828858910_1161901278283511072_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-N1jI1mSc0hQ/YLfCDiu-XEI/AAAAAAAAOsI/KcVGtSvHDhc9PhLcSKMrQww9Mfk_iusOgCLcBGAsYHQ/s2048/17854804_10209034911090907_6013383141377930042_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-u2SCaUQ0QiY/YLfCAaFVkgI/AAAAAAAAOrs/PqHRI4m4b_kL3dFB5uzPLq4VxDLFeBhnwCLcBGAsYHQ/s2048/16402482_10208503512446273_3449005757333329468_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-aRtJXcIBkAc/YLfB6oLVXGI/AAAAAAAAOqw/3dXRB6s7aiA8COKeEEYh8qrgFaXNcxoywCLcBGAsYHQ/s960/13882189_10207026366718553_2408832815959962227_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-Ve31lcdxwaI/YLfB6Zk5GjI/AAAAAAAAOqs/z-JvdLveNKk4fBZbV_1D7fo0bO54auxBACLcBGAsYHQ/s960/14064038_10207148683176388_1665646472647504392_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-2RbLT953SqA/YLfB4svpuuI/AAAAAAAAOqY/YHGWRFfKKSwMlGYaDKlLblNKXE_VCg7FgCLcBGAsYHQ/s960/13501945_10206702323257669_4067303230317057964_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-kmP7xxlqMfg/YLfB3iJSy9I/AAAAAAAAOqM/G5jfO9mzo7QDogBVN1Cmtc8wCdA1BYIYACLcBGAsYHQ/s960/13445458_10206616695677033_8039413156738512633_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-lItjAEONWjU/YLfB4FVjrgI/AAAAAAAAOqQ/aOqUvjVakXgQP7ouNo1V4RX-IEG5Rc35ACLcBGAsYHQ/s960/13494885_10206700968543802_2586404305307961139_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-XF939G79tEc/YLfB5bHXUgI/AAAAAAAAOqg/08VURTT98bwYJycN0LIAePmmPKR8wOHJgCLcBGAsYHQ/s960/13537625_10206700963503676_2377927342351563205_n.jpg"
      />
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-PpuwVeFeO0w/YLfCIUCkOSI/AAAAAAAAOs0/kxHFwqGPj-MVztEP6vgWV8Z0fS_gPYbeACLcBGAsYHQ/s2048/18953314_10209551377442243_3396338501492840522_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-S8ByDwT2se0/YLfCFJqp_6I/AAAAAAAAOsY/AvmVtpgmRNgWiasy5Rk-MBisUmyR5oZWgCLcBGAsYHQ/s2048/18156377_10209203921276056_3087814225938880391_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-y1uemkVvSJU/YLfCECpjACI/AAAAAAAAOsM/A9Mc6YHJkugWcyr3JAzXATDf74YxGblIQCLcBGAsYHQ/s2048/17855106_10209017879785135_8766755027298772590_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-W3aCFYVHTMY/YLfB_FoLoAI/AAAAAAAAOrg/mQe_gMAEinAdNgpgZRk1CAbOZVWG2QegACLcBGAsYHQ/s960/14344935_10207279773333560_1979125837919846585_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-sEsgDWT9xLA/YLfB_UCz0fI/AAAAAAAAOro/LRtAz3EgGeI9XGVFCvcQ_U1x_DcxImONACLcBGAsYHQ/s2048/14435304_10207412656735562_2583385985044705570_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-vhNJNX7btwM/YLfB9jDrTbI/AAAAAAAAOrU/I8ViBuqdi-8MHx5wwqnDYNVUXzf-0DViACLcBGAsYHQ/s960/14199398_10207159019754796_6379052157786296699_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-BlK_xnh88bg/YLfB88COTTI/AAAAAAAAOrM/xj2ea7Zn1YQr1vJIAh_uBNZUiGPfXbl8gCLcBGAsYHQ/s960/14192094_10207152806599471_4530809603668454052_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-BKf4DzMRz0I/YLfB8LW4V5I/AAAAAAAAOrA/1q6g8Tfgs5cQwO19Az42UcIF4tnI7s-ewCLcBGAsYHQ/s960/14102718_10207148682976383_8936424533508068141_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-JaYCAf-h_W8/YLfB8X6kjeI/AAAAAAAAOrE/vNRjn_7Oid8a4nRr_g0DWJeo2cxnljq2ACLcBGAsYHQ/s960/14141711_10207120666835997_8508448415665763818_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-oMRDGCW1jKE/YLfB8u1tYhI/AAAAAAAAOrI/9wyNqmaR6Wg8jUDcqe-ImB5NZ80Q-aNMACLcBGAsYHQ/s960/14184418_10207218167593455_1365997066710812535_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-qLpe_qzTIXk/YLfB9DUdX_I/AAAAAAAAOrQ/Cfe3hxsA-IwO98GHHorTQJca20Kn54UpwCLcBGAsYHQ/s960/14199238_10207237241910301_1593887801130781585_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-CvW2422KPqo/YLfB7c74KEI/AAAAAAAAOq4/-nejibx3H-YQ2mGdYARu1LADg0KvVV1PQCLcBGAsYHQ/s960/14088596_10207159106756971_2400656044542344527_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-eTmNquR0_vM/YLfB4Z_WNuI/AAAAAAAAOqU/4HGSB87JWYAu7RIjgOTGmqfwjp8vyYjjACLcBGAsYHQ/s960/13501770_10206730736047971_7902156477505331140_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-0fpNAc6cuI4/YLfB2UpxA-I/AAAAAAAAOqA/7q_e3fmDkYMDqqoCkpAW1zMuLUQnJdxNQCLcBGAsYHQ/s960/13335750_10206583711532450_4308826111850619021_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-zz6ZBWey6cE/YLfB2SEvuHI/AAAAAAAAOqE/wn4VDZT7zw8_2R_uAwRz9xlURjMkaTRlQCLcBGAsYHQ/s960/13346591_10206574468541381_4843643184755347251_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-DEN_O5NSnO8/YLfCEeKeBQI/AAAAAAAAOsQ/cEyX45qWrU0VPYKHT6_qXZZL0rqvCilpQCLcBGAsYHQ/s2048/18056401_10209181727521226_2401436607615975549_o.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-Se8TMKgBcp8/YLfCKckrnBI/AAAAAAAAOtM/l55_l61sG0I0mUBfdJdlWlk5WyFjvjQnQCLcBGAsYHQ/s2048/26232735_10211155035692697_8588651837254134095_o.jpg"
      />
    </div>
    <div class="col-6 col-md-6 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-r-tU7uIq41U/YLfB1ekdrkI/AAAAAAAAOp0/dueqcwBH4mMhOdRwTO9IBqdsnA-fskIcgCLcBGAsYHQ/s2048/13246175_10206495994699584_336663701417386811_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-fRqPVxcBGeE/YLfCMla9DsI/AAAAAAAAOto/7_MRfDfJExwWBQ0kTMOtQD-A1U1zeqKtwCLcBGAsYHQ/s2048/50396445_10213758526738346_5195822766346993664_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-65Cr92CVVhY/YLfCCsIQGKI/AAAAAAAAOr8/wpQJ1HsVolEyYZvhzzhVVnXo63brm0SdACLcBGAsYHQ/s2048/17546830_10208980346526827_425522103728483555_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-AlnIFvTfWBU/YLfCBauA7UI/AAAAAAAAOr0/lVqisTmcOZ0HeKIyLNgCHhvyT2gfpBrywCLcBGAsYHQ/s2048/17492742_10208992555912054_5327562551183782731_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-VvdWt0Xxb-A/YLfCBDOpwiI/AAAAAAAAOrw/8GCZ_UomTO8MKHnlMB2_vCo1zLaoW7mxwCLcBGAsYHQ/s2048/17492711_10208992490550420_3855322058131929147_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-HduEH49KLpo/YLfCBS99yEI/AAAAAAAAOr4/8qv3ZXMjs1kR7Muh6axQmOLGbwnZ17QjwCLcBGAsYHQ/s2048/17505084_10208927489445433_8299680891748473903_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-GQdCy-vwelo/YLfB-GDEeTI/AAAAAAAAOrc/tC9-hf964sw2ItM59BA16TQq_R162sV3wCLcBGAsYHQ/s960/14238140_10207237481236284_3802032305288089029_n.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-5TR7qyoS8tY/YLfCEqPZjSI/AAAAAAAAOsU/OzYT0itb_24xoHngJhjrTls5UXY1EBUcgCLcBGAsYHQ/s2048/18121566_10209194240794050_8534669187724572094_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="beauty shoot photo art gallery"
        src="https://1.bp.blogspot.com/-QDP4gOQwtj8/YLfB_QL-HeI/AAAAAAAAOrk/6o-evJNHhVoiriotlsa32p5TLDIwYjbUACLcBGAsYHQ/s2048/15195984_10207888922841917_8002652898370303642_o.jpg"
      />
    </div>
    <div class="col-6 col-md-4 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography art beauty fashion"
        src="https://1.bp.blogspot.com/-jE3BwRXarBg/YLfB-Odyd6I/AAAAAAAAOrY/ljnP313HCIkDXR77faYZmom5NAcVxCQnACLcBGAsYHQ/s960/14199474_10207218166673432_4634852013468629546_n.jpg"
      />
    </div>
    <div class="col-12 col-md-12 p-3 text-center">
      <img
        class="img-fluid shadow rounded"
        alt="photography beauty art fashion"
        src="https://1.bp.blogspot.com/-hVtG4an23ts/YLfCNswWniI/AAAAAAAAOt0/VhvNEwOSVvgnETKMrfH4ZbiW3cvUK4BMgCLcBGAsYHQ/s1720/81314795_10216219163012715_8744767145113550848_n.jpg"
      />
    </div>

    <div class="col-12 col-md-12 p-3 text-center">
      <p>
        <a
          routerLink="/photography/beauty/retouch"
          class="btn btn-outline-light text-white"
          >Continue to page 2 →</a
        >
      </p>
    </div>
  </div>
</div>
